import React, { useState, useEffect, useRef } from 'react';
import { getAllCategories } from '../../../containers/CategoriesContainer';
import { FaChevronRight } from 'react-icons/fa';
import { useLanguage } from '../../../LanguageContext';

const Category = ({ props }) => {
  const [allCategories, setAllCategories] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const { translations, changeLanguage } = useLanguage();
  const menuRef = useRef(null);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const categories = await getAllCategories();
      setAllCategories(categories);
    };
    fetchData();
  }, []);

  function loadAll(props) {
    if (props.id == null) {
      return (
        <li className="nav_sub-menu_item todas-categorias row" ref={menuRef}>
          <a href="#" className="nav_sub-menu_link nav-dropdown col-10 m-0">
            <span>{translations.MenuPrincipal?.Todas_las_categorias}</span>
          </a>
          <ul key={`todas-categoriasUl-null`} className="list-unstyled mb-0 nav_sub-menu nav_sub-menu--interno">
            {allCategories
              .filter(newarr => newarr.idClassificacaoNivelAnterior === null)
              .map((category, index) => (
                <li key={`todas-categorias-${category.idClassificacaoNivel}-${index}`} className="nav_sub-menu_item">
                  <a
                    href={`/busca?c=${category.idClassificacaoNivel}`}
                    className="nav_sub-menu_link"
                    onClick={() => {
                      localStorage.removeItem('MyPageNumber');
                    }}
                  >
                    {category.Descricao}
                  </a>
                </li>
              ))}
          </ul>
          <FaChevronRight className="fa mt-2" size={14} />
        </li>
      );
    }
  }

  function loadMenus(props) {
    if (props.id == null) {
      return allCategories
        .filter(newarr => newarr.idClassificacaoNivelAnterior === null)
        .slice(0, 10)
        .map((category, index) => (
          <li key={`catNav-${category.idClassificacaoNivel}-${index}`} className="nav_sub-menu_item row">
            <a href="#" className="nav_sub-menu_link nav-dropdown col-10 m-0"><span>{category.Descricao}</span></a>
            <ul key={`catNavUl${category.idClassificacaoNivel}-${category.idClassificacaoNivelAnterior}`} className="list-unstyled mb-0 nav_sub-menu nav_sub-menu--interno">
              {allCategories
                .filter(newarr => newarr.idClassificacaoNivelAnterior === category.idClassificacaoNivel)
                .slice(0, 27)
                .map((subCategory, subIndex) => (
                  <li key={`subCat-${subCategory.idClassificacaoNivel}-${subCategory.idClassificacaoNivelAnterior}-${index}-${subIndex}`} className="nav_sub-menu_item">
                    <a
                      href={`/busca?c=${subCategory.idClassificacaoNivelAnterior}&s=${subCategory.idClassificacaoNivel}`}
                      className="nav_sub-menu_link"
                      onClick={() => { localStorage.removeItem('MyPageNumber') }}
                    >
                      {subCategory.Descricao}
                    </a>
                  </li>
                ))}
            </ul>
            <FaChevronRight className="fa mt-2" size={14} />
          </li>
        ));
    } else {
      return allCategories
        .filter(newarr => newarr.idClassificacaoNivelAnterior === props.id)
        .map(category => (
          <li id={`allCats${category.idClassificacaoNivel}-${category.idClassificacaoNivelAnterior}`} key={`allCats${category.idClassificacaoNivel}-${category.idClassificacaoNivelAnterior}`} className="nav_sub-menu_item">
            <a
              href={`/busca?c=${category.idClassificacaoNivelAnterior}&s=${category.idClassificacaoNivel}`}
              className="nav_sub-menu_link"
              onClick={() => { localStorage.removeItem('MyPageNumber') }}
            >
              {category.Descricao}
            </a>
          </li>
        ));
    }
  }

  function MenuItem({ descricao, children }) {
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
  
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  
    const handleClick = () => {
      setIsClicked(!isClicked); 
    };
  
    return (
      <li className="nav-item col-6 m-0 p-0">
        <button
          style={{
            width: '100%',
            border: 'none',
            backgroundColor: isClicked ? '#005f8d' : isHovered ? '#005f8d' : '#008ed1',
            color: 'white',
            cursor: 'pointer',
          }}
          className="nav-link nav-dropdown px-0 pr-4 justify-content-center"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          {descricao}
        </button>
  
        <ul className="list-unstyled col-12 mb-0 nav_sub-menu nav_sub-menu--interno">
          {children}
        </ul>
      </li>
    );
  }

  function SubMenuItem({ descricao, children }) {
    const handleClick = (event) => {
      event.preventDefault(); 
      setIsActive(!isActive); 
    };

    return (
      <li className="nav-item col-2 m-0 p-0" ref={menuRef}>
        <a
          role="button"
          className="nav-link nav-dropdown px-2 py-0 justify-content-center"
          onClick={handleClick}
        >
          {descricao}
        </a>
        <ul className={`list-unstyled mb-0 nav_sub-menu nav_sub-menu--interno position-absolute overflow-visible ${isActive ? 'active' : ''}`} style={{ zIndex: 1000 }}>
          {children}
        </ul>
      </li>
    );
  }

  return (
    <>
      {props.id == null ? (
        <MenuItem descricao={props.descricao}>
          {loadMenus(props)}
          {loadAll(props)}
        </MenuItem>
      ) : (
        <SubMenuItem descricao={props.descricao}>
          {loadMenus(props)}
          {loadAll(props)}
        </SubMenuItem>
      )}
    </>
  );
}

export default Category;
