import React, { useEffect, useState } from 'react';
import { getItemSession } from '../../../utils';
import logo from './icon-home-pb.png'
import { useHistory } from 'react-router-dom'; 
import { useLanguage } from '../../../LanguageContext';

const LogoHome = () => {
    const [caminho, setCaminho] = useState('');
    const history = useHistory(); 

    const { translations, changeLanguage } = useLanguage();

    useEffect(() => {
        let filial = getItemSession('_filialSelecionada') ?? 0;
        let token = getItemSession('_token') ?? '';

        if (filial === 0 && token === '') {
            setCaminho('/login_vitrine');
        } else if (filial === 0 && token !== '') {
            setCaminho('/login_filial');
        } else {
            setCaminho('/');
        }
    }, []);

    const redirectToPage = () => {        
        history.push(caminho);
    };

    return (
        <button tabIndex="0" className='logo-home' aria-label="Logo da página, clique para redirecionar para a página inicial" onClick={redirectToPage}>
            <img src={logo} alt="Shimano"/>
            <p className="logo_home">{translations.Header?.Home}</p>
        </button>    
    );
}

export default LogoHome;
