import React, { useEffect } from 'react';
import { useLanguage } from '../LanguageContext';

import { scrollToTop } from '../utils';

const PerguntasFrequentesFAQs = () => {

    const { translations, changeLanguage } = useLanguage();

    useEffect(() => {
        scrollToTop();
    });

    return (
        <>

            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>{translations.PerguntasFrequentesFAQs?.Preguntas_Frecuente}</h1>
                    </div>
                    <hr />
                </div>
                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Como_ser_un_cliente}</h4>
                    <ul>
                        <li>
                            {translations.PerguntasFrequentesFAQs?.tienda_fisica}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Documentos_necesarios}</h4>
                    <ul>
                        <li>
                            {translations.PerguntasFrequentesFAQs?.Constancia_Fiscal}
                        </li>
                        <li>
                            {translations.PerguntasFrequentesFAQs?.Acta_Constitutiva}
                        </li>
                        <li>
                            {translations.PerguntasFrequentesFAQs?.Identificacion_Oficial}
                        </li>
                        <li>
                            {translations.PerguntasFrequentesFAQs?.Comprobante_de_Domicilio}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Policas_Comerciales}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Proceso_para_ingresar}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.cliente_y_distribuidor}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Mecanicos_certificados}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Compras_regulares}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Manejar_todas}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Asignacion_de_espacio}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Acceso_al_sistema}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Asesor_comercial}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Contacto_para_problemas}</h4>
                    <ol>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Post_Ventas} <b> {translations.PerguntasFrequentesFAQs?.Tel_ventas}</b>
                        </li>
                    </ol>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Estatus_de_entrega}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.recibas_el_correo}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Participacion_de_Shimano}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.cliente_activo}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Compras_constantes}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Lugar_evento}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Apoyo_patrocinio}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Datos_bancarios} </h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Numero_Cuenta} - <b>{translations.PerguntasFrequentesFAQs?.Numero_Cuenta_tel}</b>
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.CLABE_Interbancaria} – <b>{translations.PerguntasFrequentesFAQs?.CLABE_Interbancaria_num}</b>
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Beneficiario} – <b>{translations.PerguntasFrequentesFAQs?.Bike_Fishing}</b>
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Dudas_estados_cuenta}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Contacta_asesor}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Correo_automatico}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Datos_factura}</h4>
                    <ul>
                        <li>
                            {translations.PerguntasFrequentesFAQs?.Datos_fiscales_factura}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Actualizacion_datos_fiscales}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Envia_constancia}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Problemas_acceso_B2B}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Usuario_contrasena}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Nuevo_link}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Otro_navegador}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Eliminar_cookies}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Contacta_asesor_Telemarketing}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Linea_credito}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Compras_constantes_linea}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Credito_promedio_mensual}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Nuevas_politicas}
                        </li>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Firma_politica_pagare}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Material_POP}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Material_POP_distribuidores}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Catalogos_marca}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Catalogos_digitales}
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PerguntasFrequentesFAQs?.Exhibidores_marcas}</h4>
                    <ul>
                        <li>
                        {translations.PerguntasFrequentesFAQs?.Exhibidores_disponibilidad}
                        </li>
                    </ul>
                </div>
            </section>
            <br />

        </>
    );
}

export default PerguntasFrequentesFAQs;