import React from 'react';
import '../../scss/common.scss';
import '../../scss/tipbar.scss';
import { FaBoxOpen, FaShieldAlt, FaTruck, FaPhoneAlt } from 'react-icons/fa'
import { useLanguage } from '../../LanguageContext';

const TipBar = () => {
  const { translations, changeLanguage } = useLanguage();
  const language = localStorage.getItem('language')
  return (
    <>
      <div className="box">
        <div className="container">
          <div className="blkTipBox">
            <div className="row">
              {language === 'en-US' ?
                ''
                :
                <div className="col-md-3 col-12 d-flex flex-row align-items-center flex-wrap justify-content-center">

                  <div className="tipBar">
                    <div className="tipBar__icon"><FaBoxOpen color="dodgerblue" /></div>
                    <div className="tipBar__texts">
                      <p><strong>{translations.TipBar?.Envio_Gratis} </strong></p>
                      <p>{translations.TipBar?.IVA}</p>
                    </div>
                  </div>
                </div>
              }

              <div className="col-md-3 col-12 d-flex flex-row align-items-center flex-wrap justify-content-center">
                <div className="tipBar">
                  <div className="tipBar__icon"><FaShieldAlt color="dodgerblue" /></div>
                  <div className="tipBar__texts">
                    <p><strong>{translations.TipBar?.Seguro}</strong></p>
                    <p>{translations.TipBar?.Entrega_Garantizada}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 d-flex flex-row align-items-center flex-wrap justify-content-center">
                <div className="tipBar">
                  <div className="tipBar__icon"><FaTruck color="dodgerblue" /></div>
                  <div className="tipBar__texts">
                    <p><strong>{translations.TipBar?.Entrega_Rapida}</strong></p>
                    <p>{translations.TipBar?.Velocida_de_Entrega}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 d-flex flex-row align-items-center flex-wrap justify-content-center">
                <div className="tipBar">
                  <div className="tipBar__icon"><FaPhoneAlt color="dodgerblue" /></div>
                  <div className="tipBar__texts">
                    <p><strong>{translations.TipBar?.Contacto_Distribuidores}</strong></p>
                    <p>{translations.TipBar?.Tel_Distribuidores}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TipBar;