import React, { } from 'react';
import { fetchGeradorPDF } from '../../services/products-api';
import { getItemSession } from '../../utils';
import { useState } from 'react';
import { useLanguage } from '../../LanguageContext';

const PDFGenerator = (name) => {
  const [isCartPDF, setIsCartPDF] = useState(true);
  const ukpedido = getItemSession('_pedido').toString();

  const { translations, changeLanguage } = useLanguage();
  
  const parametroControle = {
    "ukPedido": ukpedido
  }

  let ukPedidoJson = JSON.stringify(parametroControle)
  const handlePDF = () => {
    fetchGeradorPDF(ukPedidoJson)
        .then(result => {
            if (result.data.Data) {
              const data = result.data.Data.FileContents;
              const arrayBuffer = base64ToArrayBuffer(data);
              createAndDownloadBlobFile(arrayBuffer, result.data.Data.FileDownloadName);
            }
        })
        .catch(reject => {            
        })
  }

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  function createAndDownloadBlobFile(body, filename) {
    const blob = new Blob([body]);
    const fileName = filename;
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  }

  useState(() => {
    if(name.className === 'cart-pdf')
    {
      setIsCartPDF(true)
    } else if (name.className === 'dropdown-pdf')
    {
      setIsCartPDF(false)

    }
  },[])

  const spanClassName = isCartPDF
    ? 'btn btn--cinza btn--block btn--full btn--bold pdf-cotizacao'
    : 'btn btn--cinza pdf-cotizacao-span';

  return (
    <span onClick={handlePDF} className={spanClassName}>
      {translations.PDFGenerator?.Cotizacion}
    </span>
  );
}

export default PDFGenerator;
