import React, { useState } from 'react';
import { useLanguage } from '../../../LanguageContext'; // Ajuste o caminho conforme necessário
import { FaGlobe } from 'react-icons/fa';
import styled from 'styled-components';
import ReactCountryFlag from "react-country-flag"

const DropDownContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;



const Select = styled.select`
    position: absolute;
    top: 100%; /* Posiciona o dropdown abaixo do ícone */
    left: 0;
    font-size: 12px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    border: none;
    z-index: 1000; /* Garante que o dropdown fique acima de outros elementos */
    display: ${props => props.show ? 'block' : 'none'};

    &:hover {
        background-color: rgba(0, 239, 239, 0.404); /* Gray transparent */
    }
`;

const DropDownLanguage = (params) => {
    const { language, changeLanguage } = useLanguage(); // Obtém o estado e a função do contexto
    const [showSelect, setShowSelect] = useState(false);

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        setShowSelect(false);
        changeLanguage(selectedLanguage); // Atualiza o idioma no contexto
    };

    const handleGlobeClick = () => {
        setShowSelect(!showSelect); // Alterna a visibilidade do select ao clicar no ícone
    };

    return (
        <DropDownContainer>
            {language === 'es-MX' ?
                <ReactCountryFlag countryCode="MX" onClick={handleGlobeClick} svg  style={{ width: '32px', height: '32px' }} />
                :
                <ReactCountryFlag countryCode="US" onClick={handleGlobeClick} svg  style={{ width: '32px', height: '32px' }} />
            }

            {/* <GlobeIcon onClick={handleGlobeClick} />  */}
            {params.tipe ?? <span style={{ fontSize: '12px' }}>{language}</span>}

            {params.tipe ?
                <Select value={language} show={showSelect} onChange={handleLanguageChange}>
                    <option value="es-MX">Español (México)</option>
                    <option value="en-US">English (EUA)</option>
                </Select>
                :
                <Select value={language} show={true} onChange={handleLanguageChange}>
                    <option value="es-MX">Español (México)</option>
                    <option value="en-US">English (EUA)</option>
                </Select>
            }
        </DropDownContainer>
    );
};

export default DropDownLanguage;
