import React, { createElement,useState,useEffect } from 'react';
import { formatMoney,getItemSession } from '../../../utils';
import PDFGenerator from '../../ExportData/PDFCotizacao';
import { fetchCart } from './../../../services/cart-api';
import { useLanguage } from '../../../LanguageContext';

const Cart = ({ items, amount }) => {
    const [invoice, setInvoice] = useState([]);

    const { translations, changeLanguage } = useLanguage();

// amount =  items.reduce((accumulator, item) => 
//                                             accumulator + item.ValorTotalFinal, 0);

useEffect(() => {
    const pedido = getItemSession('_pedido');
    fetchCart(pedido)
        .then(result => {
            setInvoice(result.data.Data?.Dados[0])
        })
        .catch(reject => {
            console.error(reject);
        })

}, [items])


return (   
    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="CartDropdown">
        {items?.length > 0 &&
            <>
                <div className="dropdown-menu_cont">
                    <ul className="list-unstyled mb-0">
                        {items.map((item, index) => (
                            <li key={`cart-dropdown-item-${index}`} className="dropdown_cart_item">
                                {createElement('a', { href: `/produto/${item.idProduto}`, className: 'dropdown-menu_link' }, item.Descricao)}
                                <div className="dropdown_cart_item_footer">
                                    <p>{translations.Cartpage?.Cantidad}: {item.Quantidade}</p>
                                    <p>$ {formatMoney(item.ValorTotalFinal, 2, '.', ',')}</p>
                                </div>
                            </li>

                        ))}
                    </ul>
                </div>
                <div className="dropdown-menu_footer pdf-cota">
                    <div className="total">
                        <p>
                            Subtotal:
                            <b>$ {formatMoney(invoice?.Valor, 2, '.', ',')}</b>
                        </p>
                    </div>
                    <PDFGenerator className='dropdown-pdf'/>


                    {createElement('a', { href: '/carrinho', className: 'btn btn--cinza', style:{ fontSize: '12px' }}, translations.Cart?.Carrinho)}
                </div>
            </>}
        {items?.length === 0 &&
            <div className="dropdown-menu_cont text-center">
                {translations.Cart?.Carrinho_vazio}
            </div>}
    </div>
)}

export default Cart;