import React, { useState, useEffect, createElement } from 'react';
import Modal from "react-bootstrap/Modal";
import { Loader } from '../components/Loader/Loader';
import { formatMoney, disconnect, scrollToTop } from '../utils';
import { fetchCartItems, removeFromCart, updateCart, postEndCart } from '../services/cart-api';
import { fetchProductsBySuggestion } from '../services/products-api';
import ProductCard from '../components/Product/ProductCard';
import { arrOrigin } from '../constants';
import * as CartActions from '../store/actions/cart'
import { connect } from 'react-redux';
import { setItemSession } from '../utils';
import { FaTrashAlt, FaWindowClose } from 'react-icons/fa';
import ModalTeste from '../components/Modal';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga'
import PDFGenerator from '../components/ExportData/PDFCotizacao';
import { useLanguage } from '../LanguageContext';

const Cart = ({ cartItems, refreshCartFn }) => {
    const [productsSuggestion, setProdutosSuggestion] = useState([]);
    const [isLoadingSuggestion, setLoadingSuggestion] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [inputValue, setInputValue] = useState({});
    const [modalDisconect, setModalDisconect] = useState(false);
    
    // modal
    const [isOpen, setIsOpen] = useState(false);

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModalRemove = () => {
        itemsUnavailable.map(product => (
            removeItem(product.idProduto, product.Item)
        ))
        setItemsUnavailable([])
        setIsOpen(false);
    };
    // fim modal
    const { translations, changeLanguage } = useLanguage();
    const [qtd, setQtd] = useState(0);
    const [amount, setAmount] = useState(0);
    const [items, setItems] = useState([]);
    const [itemsUnavailable, setItemsUnavailable] = useState([]);


    useEffect(() => {
        if (cartItems.Dados.length === 0) {
            setLoading(true);

            postEndCart()
                .then(resultEndCart => {
                    if (resultEndCart.data.Codigo === 400 && resultEndCart.data.CodigoInterno === 18) {

                        setLoading(false);
                    } else {
                        setItemSession('_pedido', resultEndCart.data.Data.Pedido.ukPedido);

                        const newStorage = {
                            "QuantidadeRegistrosTotal": 0,
                            "QuantidadeRegistrosRetornados": 0,
                            "Paginas": 1,
                            "ResultadosPorPagina": 0,
                            "Dados": []
                        }
                        setItemSession('_carrinho', JSON.stringify(newStorage));
                        refreshCartFn(newStorage);

                        setLoading(false);
                    }
                })
                .catch(reject => {
                    console.log(reject, 'post End Cart')
                })
        }
    }, [items.length, refreshCartFn])

    const decrement = (idProduto, item, quantity) => {
        if (quantity > 1) {
            update(idProduto, item, quantity - 1);
        }
    }

    const increment = (idProduto, item, quantity) => {
        update(idProduto, item, quantity + 1);
    }

    const handleChange = (e) => {
        setInputValue({ [e.target.name]: e.target.value });
    }

    const handleBlur = async (idProduto, item, e) => {
        e.preventDefault();

        let quantity = inputValue[e.target.name];

        if (parseInt(quantity) > 0) {
            await update(idProduto, item, quantity);
        }
    }
    Cookies.remove('LinkOld');
    const update = async (idProduto, item, quantity) => {
        setLoading(true);
        setAmount(0);
        setQtd(0);

        await updateCart(idProduto, item, quantity)
            .then(resultUpdate => {
                if (resultUpdate.data.Codigo === 500) {
                    if (resultUpdate.data.CodigoInterno === 4) {
                        disconnect()
                        return
                    } else {
                        alert(resultUpdate.data.Status);
                    }
                }

                localFetchItem()
            })
            .catch(
                reject => {
                    console.log(reject);
                }
            )
    }

    useEffect(() => { ReactGA.pageview(window.location.pathname + window.location.search) }, [])


    // Scroll
    useEffect(() => scrollToTop(), [isLoading]);

    const removeItem = (idProduto, item) => {
        setLoading(true);
        setAmount(0);
        setQtd(0);

        removeFromCart(idProduto, item)
            .then(result => {
                localFetchItem();
            });
    }

    async function CartItemsAsync() {
        fetchCartItems()
            .then(result => {
                setItems(result.data.Data.Dados)
                setQtd(result.data.Data.QuantidadeRegistrosTotal)
                const total = result.data.Data.Dados.reduce((acc, curr) => acc + curr.ValorTotalFinal, 0);
                setAmount(total)
                const unavailable = result.data.Data.Dados.filter(newarr => newarr.Estoque === 0);
                const qdtEstoque = result.data.Data.Dados.filter(newarr => newarr.Estoque < newarr.Quantidade);

                if (unavailable.length > 0) {
                    showModal()
                }
                if (qdtEstoque.length > 0) {
                    setItemsUnavailable(qdtEstoque);
                    showModal()
                }

                setLoading(false);

                let inputArray = [];
                result.data.Data.Dados.map(item => inputArray.push(item.Quantidade));
                setInputValue(inputArray);
            })
            .catch(reject => {
                setModalDisconect(true)
            })
    }

    // Load Data
    useEffect(() => {
        scrollToTop();
        setLoading(true);
        CartItemsAsync();
    }, [cartItems]);

    useEffect(() => {
        fetchProductsBySuggestion(5)
            .then(result => {
                if (result.data.Data.QuantidadeRegistrosRetornados > 0) {
                    setProdutosSuggestion(result.data.Data.Dados)
                }
            })
            .then(result => setLoadingSuggestion(false))
    }, [])

    const localFetchItem = async () => {
        setTimeout(async () => {
            await fetchCartItems()
                .then(result => {
                    setItemSession('_carrinho', JSON.stringify(result.data.Data))
                    refreshCartFn(result.data.Data)
                })
                .then(result => setLoading(false))
                .catch(reject => {
                    setModalDisconect(true)
                })
        }, 1000);
    }

    return (
        <>

            <ModalTeste itIsTrue={modalDisconect} />
            <section className="meu-carrinho container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h2 className="titulo h2">{translations.Cartpage?.Mi_carrito}</h2>
                        <h6 className="titulo h6">({qtd} {translations.Cartpage?.Articulos})</h6>
                    </div>
                </div>
                <div className="tabela-items--wrapper">
                    {!isLoading ? (
                        items.length > 0 ? (
                            <table className="tabela tabela-items">
                                <thead>
                                    <tr>
                                        <th>{translations.Cartpage?.Imagen}</th>
                                        <th>{translations.Cartpage?.Nombre_del_producto}</th>
                                        <th>{translations.Cartpage?.Cantidad}</th>
                                        <th>{translations.Cartpage?.Valor_Unitario}</th>
                                        <th>{translations.Cartpage?.Valor_Total}</th>
                                        <th>{translations.Cartpage?.Aciones}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => (
                                        <tr key={`cart-item-${index}`} className={`${item.Estoque < 1 && translations.Cartpage?.indisponivel}`}>
                                            <td className="card-item--header">
                                                <img src={
                                                    item.LinkImagemLista
                                                } alt="" className="img-card-produto" />
                                            </td>
                                            <td>
                                                <p>{item.Descricao}</p>
                                                <p>PN: {item.EAN}</p>
                                                 <p className="tabela-items--estoque">{translations.ProductCard?.Cantidad_Caja}:{item.UnidadesPorCaixa}</p>
                                                <p className="tabela-items--estoque">
                                                    {item.Estoque < 1
                                                        ? translations.Cartpage?.Producto_no_disponibles
                                                        : `${item.Estoque}` + translations.Cartpage?.disponibles
                                                    }
                                                </p>
                                            </td>
                                            <td>
                                                <form className="form-adicionar">
                                                    <button
                                                        type="button"
                                                        disabled={`${item.Estoque < 1 ? 'disabled' : ''}`}
                                                        name="btn-item"
                                                        className="btn form-adicionar--btn"
                                                        onClick={() => decrement(item.idProduto, item.Item, item.Quantidade)}>-</button>
                                                    <input
                                                        type="number"
                                                        disabled={`${item.Estoque < 1 ? 'disabled' : ''}`}
                                                        name={`quantity-${item.Item}`}
                                                        // usado o inputValue e nao o item.Quantidade para poder pegar valor do useState
                                                        value={inputValue[index]}
                                                        className="card-item--counter"
                                                        maxLength={4}
                                                        onChange={handleChange}
                                                        onBlur={(e) => handleBlur(item.idProduto, item.Item, e)}
                                                        // enter: e.which == 13
                                                        onKeyPress={(e) => e.which === 13 ? handleBlur(item.idProduto, item.Item, e) : ''}
                                                    />
                                                    <button
                                                        type="button"
                                                        disabled={`${item.Estoque < 1 ? 'disabled' : ''}`}
                                                        name="btn-item"
                                                        className="btn form-adicionar--btn"
                                                        onClick={() => increment(item.idProduto, item.Item, item.Quantidade)}>+</button>
                                                </form>
                                            </td>
                                            <td>$ {formatMoney(item.ValorFinal, 2, '.', ',')}</td>
                                            <td>$ {formatMoney(item.ValorTotalFinal, 2, '.', ',')}</td>
                                            <td className="text-center">
                                                <button type="button" onClick={() => { if (window.confirm(translations.Cartpage?.Quieres_elemininar)) removeItem(item.idProduto, item.Item) }} className="btn btn--icon tabela-items--delete"><FaTrashAlt className="tabela-items--delete" /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        ) : (
                            <span>{translations.Cartpage?.Carro_vacio}</span>
                        )
                    ) : (
                        <Loader short="false" />
                    )}
                </div>
                <div className="sect-footer">
                    {items.length > 0 &&
                        <>
                            <h3 className="titulo h3">{translations.Cartpage?.Valor_Total} <span className="c-laranja arial-bold">$ {formatMoney(amount, 2, '.', ',')} </span></h3>
                        </>
                    }
                    <div className="sect-btn">
                        {itemsUnavailable.length > 0 && items.length > 0 ?
                            <>
                                <button type="button" className="btn btn--cinza btn--block btn--full btn--bold" data-dismiss="modal" aria-label="Close" onClick={showModal}>
                                    <span>{translations.Cartpage?.Finalizar_compra}</span>
                                </button>
                            </>
                            :
                            (
                                <div className='container-btn-checkout'>
                                    <PDFGenerator className='cart-pdf' />

                                    {createElement('a', { href: '/checkout', className: "btn btn--cinza btn--block btn--full btn--bold", style: { fontSize: '12px' } }, translations.Cartpage?.Finalizar_compra)}
                                </div>
                            )
                        }

                        {createElement('a', { href: "/", className: "btn btn-link btn--bold" }, translations.Cartpage?.Sigue_comprando)}
                        {/* <a href="/" className="btn btn-link btn--bold">Sigue comprando</a> */}
                    </div>
                </div>

                <h4 className="titulo h4 mais-produtos-titulo">{translations.Cart?.Productos_increibles}</h4>
                <div className="conteudo_interno">
                    <div className="cards-container">
                        <div className="overflow-auto">
                            <div className="cards cards-produto cards-produto-width-5 mais-produtos-cards">
                                {!isLoadingSuggestion ? (
                                    productsSuggestion.map(product =>
                                        <ProductCard
                                            key={`cart-card-${product.idProduto}`}
                                            product={product}
                                            origin={arrOrigin['pagina_carrinho_incrementar']}
                                            refreshCartFnCart={refreshCartFn}
                                        />
                                    )
                                ) : (
                                    <Loader short="false" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Modal --> */}
            <Modal show={isOpen} className="modal modal-boleto fade">
                <Modal.Header>
                    <div className="modal-header_container">
                        <img src="./img/img-modal-indisponivel.png" alt="Imagem representativa de ma folha de papel" />
                        <h2 className="titulo h2">{translations.Cartpage?.Cantidad_no_disponible}</h2>
                        <div className="textModal">
                            <p>{translations.Cartpage?.Lo_sentimos__Se_han_modificado_las_existencias}</p>
                        </div>

                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { }}>
                        <FaWindowClose />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <ol className="lista-modal">
                        {itemsUnavailable.map(product => (

                            <li key={`unavailable-${product.idProduto}`} className="lista-modal_item">{product.Descricao} - {translations.Cartpage?.Estoque} {product.Estoque}</li>
                        ))}
                    </ol>
                    <button type="button" className="btn btn-modal" data-dismiss="modal" aria-label="Close" onClick={hideModalRemove}>
                        <span>{translations.Cartpage?.Eliminar}</span>
                    </button>
                </Modal.Body>
            </Modal>


        </>
    )
}


const mapStateToProps = state => ({
    cartItems: state.cart.items
})

const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart);