import React from 'react';
import '../../scss/common.scss';
import '../../scss/tipbar.scss';
import { FaBoxOpen, FaShieldAlt, FaTruck, FaBarcode, /*FaSortDown*/ } from 'react-icons/fa'
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'
import { getItemSession } from '../../utils';
import { Link } from 'react-router-dom';
import DropDownLanguage from '../Header/DropdownLanguage/DropDownLanguage';
import { useLanguage } from '../../../src/LanguageContext';


const TipBarFooter = () => {

  const { translations, changeLanguage } = useLanguage();
  const login = JSON.parse(getItemSession('_dados'));
  const language = localStorage.getItem('language')

  return (
    <>

      <div className="footer__tipBar">
        <div className="box">
          <div className="container">
            <div className="blkTipBox">
              <div className="row">
                {language === 'en-US' ?
                  ''
                  :
                  <div className="col-md-3 col-12 d-flex flex-row flex-wrap justify-content-center">
                    <div className="tipBar">
                      <div className="tipBar__icon"><FaBoxOpen color="white" /></div>
                      <div className="tipBar__texts">
                        <p><strong>{translations.TipBarFooter?.Envio_Gratis}</strong></p>
                        <p>{translations.TipBarFooter?.En_todas_compras_5000}</p>
                      </div>
                    </div>
                  </div>
                }

                <div className="col-md-3 col-12 d-flex flex-row flex-wrap justify-content-center">
                  <div className="tipBar">
                    <div className="tipBar__icon"><FaShieldAlt color="white" /></div>
                    <div className="tipBar__texts">
                      <p><strong>{translations.TipBarFooter?.Productos_Calidad}</strong></p>
                      <p>{translations.TipBarFooter?.Ofrecemos_productos}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12 d-flex flex-row flex-wrap justify-content-center">
                  <div className="tipBar">
                    <div className="tipBar__icon"><FaTruck color="white" /></div>
                    <div className="tipBar__texts">
                      <p><strong>{translations.TipBarFooter?.Entrega_Garantizada}</strong></p>
                      <p>{translations.TipBarFooter?.La_major_disponivilidad}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12 d-flex flex-row flex-wrap justify-content-center">
                  <div className="tipBar">
                    <div className="tipBar__icon"><FaBarcode color="white" /></div>
                    <div className="tipBar__texts">
                      <p><strong>{translations.TipBarFooter?.Forma_Pago}</strong></p>
                      <p>{translations.TipBarFooter?.Mas_comodidad_realizar}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer__links">
    <div className="container">
      <div className="row">
        <div className="footer__list">
          <ul className="slick-initialized slick-slider">
            <button className="slick-prev slick-arrow"></button>
            <div className="slick-list">
              <div className="slick-track"></div>
            </div>
          </ul>
        </div>
        <button className="footer__button">
          <span>Mais informações <FaSortDown color="white"/></span>
        </button>
      </div>
    </div>
  </div> */}
      <div className="footer__main">
        <div className="container">
          <div className="d-flex ml-2">
            <div className="col-xs-12 col-md-3 ml-4">
              <div className="list-unstyled">
                <strong>{translations.TipBar?.Contacto}</strong>
                <ul className="list-unstyled">
                  <li >{translations.TipBar?.Carretera_Estatal}<br /><br /></li>
                  <li>
                    <span>{translations.TipBar?.Lunes_a_Viernes}</span>
                  </li>
                  <br />
                  <li>
                    <strong>{translations.TipBar?.Canal_de_Denuncias}</strong>
                  </li>
                  <li>
                    <span>{translations.TipBar?.Email_denuncia}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-md-3">
              <div className="footer__links-institucionais">
                <div className="list-unstyled">

                  <strong>{translations.TipBar?.Cerca_la_Empresa}</strong>
                  <div className="list-unstyled">
                    {/* <li><Link to="/empresa">La empresa</Link></li> */}
                    <li><Link to="/historia-empresa">{translations.TipBar?.Historia_de_Shimano}</Link></li>
                    <li><Link to="/politica-de-troca-devolucao">{translations.TipBar?.Politica_de_Cambio}</Link></li>
                    <li><Link to="/politica-de-privacidade">{translations.PoliticsPrivacy?.Privacidad_titulo}</Link></li>
                    <li><Link to="/politica-comercial">{translations.PoliticsComercial?.Politica_Comercial}</Link></li>
                    <li><Link to="/faq">{translations.TipBarFooter?.Preguntas_Frecuentes}</Link></li>
                  </div>
                </div>
              </div>
            </div>
            {!login?.ApenasVisualizacao &&
              <div className="list-unstyled">
                <strong>{translations.DropdownUsers?.Minha_Conta}</strong>
                <div className="list-unstyled">
                  <li className="nav-item">
                    <a href="/pedidos">{translations.DropdownUsers?.Pedidos}</a>
                  </li>
                  <li className="nav-item">
                    <a href="/pendencias">{translations.PendenciesDetail?.Pendientes}</a>

                  </li>
                </div>
              </div>
            }

            <div className="footer__language" style={{
              borderRadius: "5px",
              textDecoration: "none",
              textAlign: "center",
              marginTop: '1px'
            }}><DropDownLanguage tipe={false} /></div>

            <div className="col-xs-10 col-md-3">

              {login?.ApenasVisualizacao &&
                <div className="footer__links-conta" style={{
                  backgroundColor: "#00aeef",
                  color: "white",
                  padding: "5px 20px",
                  borderRadius: "5px",
                  textDecoration: "none",
                  textAlign: "center",
                  marginRight: "0px",
                  marginLeft: "106px"
                }}>
                  <a href="https://app.pipefy.com/public/form/NTjUZkln" target="_blank" rel="noopener noreferrer" className="footer__button">              <span>{translations.TipBarFooter?.REGISTRO_DE_NUEVOS}</span>
                  </a>
                </div>
              }

            </div>

            <div className="row col-xs-12 col-md-3">
              <div className="footer__security">
                <strong>{translations.TipBarFooter?.Sellos_Redes_Sociales}</strong>
                <ul className="list-unstyled footer__selos">
                  <li>
                    <img src="https://bluecycle.vteximg.com.br/arquivos/selo_ssc_small.png?v=637389788671230000" alt="Selo Shimano Service Center" />
                  </li>
                </ul>
                <div className="footer__social">
                  <ul className="list-unstyled">
                    <li>
                      <a className="facebook" href="https://www.facebook.com/ShimanomtbMexico/" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a className="instagram" href="https://www.instagram.com/shimanomx/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a className="linkedin" href="https://www.linkedin.com/company/shimanomexico/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a className="youtube" href="https://www.youtube.com/channel/UCE2eNXmYi2-3Xa8YRQTPL8g" target="_blank" rel="noopener noreferrer">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TipBarFooter;