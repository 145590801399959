import React, { useEffect } from 'react';
// default
import { useLanguage } from '../LanguageContext';

import { scrollToTop } from '../utils';

const Privacy = () => {
    useEffect(() => {
        scrollToTop();
    })

    const { translations, changeLanguage } = useLanguage();
    return (
        <>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>{translations.PoliticsPrivacy?.Privacidad_titulo}</h1>
                    </div>
                    <hr />
                </div>
                <div className="subtitulo-politics">
                <h4>{translations.PoliticsPrivacy?.Politica_privacidad}</h4>

                {translations.PoliticsPrivacy?.recopilada}<br/>
                {translations.PoliticsPrivacy?.confidencialidad}<br/>
                {translations.PoliticsPrivacy?.suscriptores}<br/>
                {translations.PoliticsPrivacy?.sitio_web}<br/>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PoliticsPrivacy?.Anuncios}</h4>
                    {translations.PoliticsPrivacy?.recopilamos_utilizamos}
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PoliticsPrivacy?.Seguridad}</h4>

                    {translations.PoliticsPrivacy?.Para_proteger}
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PoliticsPrivacy?.Cookies_Beacons}</h4>

                    {translations.PoliticsPrivacy?.cookies_almacenar}
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PoliticsPrivacy?.Enlaces_Sitios}</h4>

                    {translations.PoliticsPrivacy?.Bike_and_Fishing}
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.PoliticsPrivacy?.Cambios_en_esta}</h4>

                    {translations.PoliticsPrivacy?.derecho_de_modificar}
                </div>
                
            </section>
            
        </>
    );
}

export default Privacy;