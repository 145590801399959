import React, {  useEffect  } from 'react';
import { useLanguage } from '../LanguageContext';


import { scrollToTop } from '../utils';

const Company = () => {
    
    useEffect(() => {
        scrollToTop();
    })
    const { translations, changeLanguage } = useLanguage();

    return (
        <>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>{translations.Company?.La_Empresa}</h1>
                    </div>
                    <hr />
                </div>
                
            </section>
            
        </>
    );
}

export default Company;