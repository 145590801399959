import React, { useEffect, useState } from 'react';
import { useLanguage } from '../LanguageContext';


import LogoServiceCenter from '../img/logo_SSC.png'
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { fetchClientSSC, fetchLocalSelecionado, } from '../services/client-service-center-api';

const ServiceCenter = () => {

    const [apiKey] = useState("AIzaSyCSRg6X88mznFycwXrvvhn95FQVgHxX2Sc");
    const [selected, setPositionSelected] = useState([]);
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [cidadesFiltradas, setCidadesFiltradas] = useState([]);
    const [limparFiltro, setLimparFiltro] = useState(1);
    const [cidadeValue, setCidadeValue] = useState(1);
    const [estadoValue, setEstadoValue] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();
    const [zoomMap, setZoomMap] = useState(6);
    const [range, setRange] = useState(50);
    const [filteredPositions, setFilteredPositions] = useState([])
    const [cidadeCard, setCidadeCard] = useState([])
    const [estadoCard, setEstadoCard] = useState([])
    const [mostrarScrol, setMostrarScrol] = useState('')
    const [displayNone, setDisplayNone] = useState('')

    const { translations, changeLanguage } = useLanguage();

    const [center, setCenter] = useState({
        lat: 19.451054,
        lng: -99.125519 //localizacao Mexico
    });

    const [localizacaoTeste] = useState({
        lat : -22.048548213339252, 
        lng: -49.97019169003949 //localizacao Marilia
    })


    const containerStyle = {
        position: 'absolute',
        width: '100%',
        height: '100%'
    };

    function removerAcentos(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    
    useEffect(() => {
        //lista com todos os estados e cidades
        fetchClientSSC()
            .then((result) => {
                if (result.data.Data || result.data.Data.Dados.length > 0) {
                    var dados = result.data.Data.Dados;
                    var locais = [];
                    const estados = [];
                    const cidades = [];

                    dados.forEach((item) => {
                        const local = {
                            fantasia: item.Fantasia,
                            address: item.EnderecoCompleto,
                            telefone: item.Telefone,
                            lat: parseFloat(item.Latitude),
                            lng: parseFloat(item.Longitude),
                            estado: item.Estado,
                            cidade: item.Cidade,
                        };
                        locais.push(local);

                        if (!estados.includes(local.estado)) {
                            estados.push(local.estado);
                            estados.sort(function(a, b) {
                                const normalizedA = removerAcentos(a.toLowerCase());
                                const normalizedB = removerAcentos(b.toLowerCase());
                                return normalizedA.localeCompare(normalizedB);
                            });
                        }
                        if (!cidades.includes(local.cidade)) {
                            cidades.push(local.cidade);
                            cidades.sort(function(a, b) {
                                const normalizedA = removerAcentos(a.toLowerCase());
                                const normalizedB = removerAcentos(b.toLowerCase());
                                return normalizedA.localeCompare(normalizedB);
                            });
                        }
                    })
                    setEstados(estados)
                    setCidades(cidades)
                }
            })

    }, [])



    const estadoSelecionado = (estadoValue, cidadeValue) => {
        //filtra as cidades a partir do Estado selecionado
        fetchLocalSelecionado(estadoValue, cidadeValue)
            .then((result) => {
                if (result.data.Data || result.data.Data?.Dados.length > 0) {
                    var dados = result.data.Data.Dados;                    
                    var locais = [];
                    var cidades = [];
                    dados.forEach((item) => {
                        const local = {
                            fantasia: item.Fantasia,
                            address: item.EnderecoCompleto,
                            telefone: item.Telefone,
                            lat: parseFloat(item.Latitude),
                            lng: parseFloat(item.Longitude),
                            estado: item.Estado,
                            cidade: item.Cidade,
                        };
                        locais.push(local);
                        
                      if (!cidades.some((cidade) => removerAcentos(cidade) === removerAcentos(local.cidade))) {
                        cidades.push(local.cidade);
                    }                        
                })
                    setCidadesFiltradas(cidades)
                    setEstadoCard(locais)
                    setFilteredPositions(locais)
                }  
                
            })               
    }

  
    useEffect(() => {
        //setar a posição no mapa a partir do estado/cidade selecionado
        var estadoSelected = [];
        filteredPositions.map((item) => {
            if (item.estado === estadoValue) {
                const local = {
                    fantasia: item.fantasia,
                    address: item.address,
                    telefone: item.telefone,
                    lat: item.lat,
                    lng: item.lng
                }
                estadoSelected.push(local)
                setEstadoCard(estadoSelected);
                mostrarCard()
            }
        })
        var cidadeSelected = [];
        filteredPositions.map((item) => {
            if (item.cidade === cidadeValue) {
                const local = {
                    fantasia: item.fantasia,
                    address: item.address,
                    telefone: item.telefone,
                    lat: item.lat,
                    lng: item.lng
                }
                cidadeSelected.push(local)
                setCidadeCard(cidadeSelected);
                mostrarCard()
            }
        })

        var localizacaoSelecionada = []
        if(estadoSelected.length > 0)
        {
            estadoSelected.map((obj) => {
                localizacaoSelecionada.push(obj);
                setarPosicao(localizacaoSelecionada)
                })
        }
        if(cidadeSelected.length > 0)
        {
            cidadeSelected.map((obj) => {
                localizacaoSelecionada.push(obj);
                setarPosicao(localizacaoSelecionada);
            })
        }   
    },[cidadeValue, estadoValue, filteredPositions])
    


    const setarPosicao = (localizacaoSelecionada) => {
      //seta o marcador no mapa a partir da localização selecionada
        if (localizacaoSelecionada.length > 0) {
            setCenter(({
                lat: localizacaoSelecionada[0].lat,
                lng: localizacaoSelecionada[0].lng
            }))
            setPositionSelected(localizacaoSelecionada)
            setZoomMap(9)
            setLimparFiltro(1)   
        }
    }

    const handleRangeChange = (e) => {
        setRange(parseInt(e.target.value));
    }

    const currentButton = () => {
        //botão utilizar minha localização
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                getLocationsWithinRadius(pos)
            })
        } else {
            alert(translations.ServiceCenter?.geolocalizacion);
        }
    } 

    const handleMarkerClick = (id, address, fantasia, telefone) => {
        setInfoWindowData({ id, address, fantasia, telefone });
        setIsOpen(true);
    };

    function getLocationsWithinRadius(center) {

        const R = 6371; // raio médio da Terra em quilômetros
        const locationsWithinRadius = [];

        const lat1 = toRadians(center.lat);
        const lon1 = toRadians(center.lng);

        filteredPositions.forEach((point) => {
            const lat2 = toRadians(point.lat);
            const lon2 = toRadians(point.lng);

            const dLat = lat2 - lat1;
            const dLon = lon2 - lon1;

            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            const distance = R * c; // distância em quilômetros

            if (distance <= range && distance > 0 && range > 0) {
                locationsWithinRadius.push(point);
            }
        });

        if (locationsWithinRadius.length > 0) {
            setCenter(({
                lat: center.lat,
                lng: center.lng
            }))
            setZoomMap(9)
            setPositionSelected(locationsWithinRadius);
            setLimparFiltro(1)           


        } else {
            alert(translations.ServiceCenter?.ubicacion);
        }
    }

    function toRadians(degrees) {
        return degrees * (Math.PI / 180);
    }

    const mostrarCard = () => {
        setMostrarScrol('scroll-card-ssc');
        setDisplayNone('liberar-display-none');
    }

    const posicaoCard = (index) => {
        //executa após clicar no card 
        if(selected[index] !== null && selected[index] !== undefined)
        {
            let obj = (({
                address: selected[index].address,
                lat: selected[index].lat,
                lng: selected[index].lng,
                fantasia: selected[index].fantasia,
                telefone: selected[index].telefone
            }))
            setCenter(({
                lat: selected[index].lat,
                lng: selected[index].lng,
            }))

            setZoomMap(20)
            handleMarkerClick(index, obj.address, obj.fantasia, obj.telefone)
        }
    }

    //gerar Google Maps URL
    const generateGoogleMapsURL = (lat, lng) => {
        return `https://www.google.com/maps?q=${lat},${lng}`;
    };

    return (
        <>
            
            <section className='section-map' id="find-map">
                <div className='container-box'>
                    <div className='container-form'>
                        <div className='loaded-map' id='is_loaded'>
                            <div className='container-none'>
                                <div className='box-1 container-left'>
                                    <div className='box-wrapper'>
                                        <div className='box1-content' id={mostrarScrol}>
                                            <div className='filter-top'>
                                                <img alt='Shimano Service Center' src={LogoServiceCenter}></img>
                                                <h2>{translations.ServiceCenter?.Encuentre_el_distribuidor}</h2>
                                                <form id='search-filter'>
                                                    <div className='filter-container'>

                                                        <div className='filter-result'>
                                                            <span className='result-right-icon'>
                                                                <i className='fas fa-chevron-down'></i>
                                                            </span>
                                                        </div>

                                                        <div className='initial-search'>
                                                            <div className='form-group'>
                                                                <select onChange={e => {
                                                                    estadoSelecionado(e.target.value)
                                                                    setEstadoValue(e.target.value)
                                                                    }} id='search-state' className='filter filter-state'>
                                                                    <option>{translations.ServiceCenter?.ESTADO}ESTADO</option>
                                                                    {estados.map((estado, index) => (
                                                                        <option key={index} value={estado}>{estado}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className='form-group'>
                                                                <select value={limparFiltro} onChange={e => {                                                                    
                                                                    estadoSelecionado(estadoValue, e.target.value); 
                                                                    setCidadeValue(e.target.value);
                                                                }} id='search-city' className='filter filter-state'>
                                                                    <option>{translations.ServiceCenter?.CIUDAD}</option>
                                                                    {cidadesFiltradas.length > 0 ? (
                                                                        cidadesFiltradas.map((cidadeF, index) => (
                                                                            <option key={index} value={cidadeF}>{cidadeF}</option>
                                                                        ))
                                                                    ) : (
                                                                        cidades.map((cidade, index) => (
                                                                            <option key={index} value={cidade}>{cidade}</option>
                                                                        ))
                                                                    )}
                                                                </select>
                                                            </div>   
                                                        </div>
                                                        {/* <button type='submit' onClick={onSubmit} className='search-location'>Buscar</button> */}
                                                        < br />
                                                    </div>
                                                </form>
                                            </div>
                                            <br/>
                                      <div className='filter-botom' id={displayNone}>
                                                                            
                                            <form id='search-filter'>
                                                <div className='filter-container'>
                                                    {(cidadeCard.length > 0 ? cidadeCard : estadoCard).map((item, index) => (
                                                        <div className='container-cards' key={index}>
                                                            <p onClick={() => posicaoCard(index)}>{item.fantasia}</p>
                                                            <br />
                                                            <span>{item.address}</span>
                                                            <br />
                                                            {item.telefone.trim() !== '' && (
                                                                <span>Teléfono: {item.telefone}</span>
                                                            )}
                                                            <br />
                                                            <a
                                                                href={generateGoogleMapsURL(center.lat, center.lng)}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {translations.ServiceCenter?.Google_Maps}
                                                            </a>
                                                        </div>
                                                    ))}
                                                </div>
                                            </form>
                                        </div>







                                            {/* Selecionar bikefitting */}
                                            {/* <div className='checkbox-cover'>
                                            <div className='form-check-group form-check service-concepts'>
                                                <label className='form-check-label' htmlFor="bikefitting.com">
                                                    <input type='checkbox' className='form-check-input' name='bikefitting.com' field_name="BrandBikefitting_YN" id='bikefitting.com'></input>
                                                    <span className='label-text'>Bikefitting.com</span>
                                                </label>
                                            </div>
                                        </div> */}



                                            {/* <RangeSlider currentLocation /> */}
                                            <div className='input-km'>
                                                <input type="range" min="1" max="250" value={range} onChange={handleRangeChange} />
                                                <p>{range} km</p>

                                                <div onClick={currentButton} className='my-location'>
                                                    <i className='my-location-icon'></i>
                                                    <span className='mi-ubicacion'>Utilizar mi ubicación actual</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='box-2 container-right'>
                                    <LoadScript
                                        googleMapsApiKey={apiKey}
                                    >
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={center}
                                            zoom={zoomMap}
                                        >
                                            {selected.map(({ address, lat, lng, fantasia, telefone }, index) => (
                                                <Marker
                                                    key={index}
                                                    position={{ lat, lng }}
                                                    onClick={() => { handleMarkerClick(index, address, fantasia, telefone); }}
                                                >
                                                    {isOpen && infoWindowData?.id === index && (
                                                        <InfoWindow 
                                                            onCloseClick={() => { setIsOpen(false) }}
                                                            position={{lat, lng}}
                                                            key={{index}}
                                                       >
                                                        <span className="info-window-content">
                                                          <h3 className="info-window-title">{fantasia}</h3>
                                                          <p className="info-window-address">{address}</p>
                                                          {telefone !== '               ' &&                                                    
                                                              <p className="info-window-phone">Teléfono: {telefone}</p>
                                                          }
                                                          <a href={generateGoogleMapsURL(lat, lng)} target="_blank" rel="noopener noreferrer">Abrir en Google Maps</a>
                                                        </span>
                                                      </InfoWindow>                                                      
                                                    )}
                                                </Marker>
                                            ))}
                                        </GoogleMap>
                                    </LoadScript>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </section>
            
        </>
    )
}
export default ServiceCenter;