import React, { useState, useEffect } from 'react';
import Cart from '../../NavBar/Cart/Cart';
import { connect } from 'react-redux';
import { getItemSession, setItemSession } from '../../../utils';
import { fetchCartItems } from '../../../services/cart-api';
import * as CartActions from '../../../store/actions/cart';
import { useLanguage } from '../../../LanguageContext';

const DropdownCart = ({items, refreshCartFn}) => {
    const [qtd, setQtd] = useState(0);
    const [amount, setAmount] = useState(0);
    const login = useState(JSON.parse(getItemSession('_dados')));
    const { translations, changeLanguage } = useLanguage();

    useEffect(() => {
        setQtd(items?.QuantidadeRegistrosTotal)

        fetchCartItems()
            .then(result => {
                if (result.data.Data.Dados?.length > 0) {
                    setItemSession('ampm_carrinho', JSON.stringify(result.data.Data));
                    if(JSON.stringify(result.data.Data) !== JSON.stringify(items)) {
                        items = result.data.Data;
                        refreshCartFn(items);
                            setQtd(items.QuantidadeRegistrosTotal)
                const total = items.Dados.reduce((acc, curr) => acc + curr.ValorTotalFinal, 0);
                
                setAmount(total)   
                    }
                }
            }) 
    }, [items])
    
    return (
        <>
            {!login.ApenasVisualizacao &&
                <div className="dropdown dropdown_cart" >
                    <button className="btn btn--icon dropdown-toggle" type="button" id="CartDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="fas fa-shopping-cart"></span>
                        <span className="dropdown_cart_number">({qtd > 0 ? qtd : translations.DropdownCart?.vazio})</span>
                    </button>
                    <Cart items={items?.Dados} amount={amount} />
                </div>
            }
        </>
    );
}
const mapStateToProps = state => ({
    items: state.cart.items
})


const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(DropdownCart);