import React, { useState, useRef, useEffect } from 'react';
import { getItemSession } from './../../../utils';

import { fetchSearchAutocomplete } from '../../../services/products-api';
import { useLanguage } from '../../../LanguageContext';


const SearchBarResponsive = (props) => {
  const [busca, setBusca] = useState("");
  const [autoComplete, setAutoComplete] = useState([]);
  const latestProps = useRef(props);
  const [searchResults, setSearchResults] = useState([]);

  const { translations, changeLanguage } = useLanguage();

  useEffect(() => { latestProps.current = props; });
  let filial = getItemSession('_filialSelecionada') ?? 0;

  useEffect(() => {
    let t = getItemSession('_token');
    if (autoComplete.length === 0) {
      fetchSearchAutocomplete(t, 0, 0).then(result => {

        let produtos = [];
        produtos = result.data?.Data?.Dados?.filter(dado => dado.Estoque !== 0)
        const dados = JSON.parse(getItemSession('_ProdutosShearch'));
        const itensArray = []
        if (produtos?.length > 0) {
          produtos.forEach(Item => {
            itensArray.push({
              id: Item.idProduto,
              name: Item.Descricao
            })
          });
          setAutoComplete(itensArray)
        }
      })
    }

  }, [autoComplete]);


  const items = autoComplete;

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setBusca(value);

    const filteredResults = items.filter((product) =>
      (product.name).toLowerCase().includes(value.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const handleSelectResult = (result) => {
    setBusca(result.name);
    setSearchResults([]);
    redirectToSearchPage(result.name);
  };

  const redirectToSearchPage = (searchTerm) => {
    const encodedSearchTerm = encodeRFC5987ValueChars(searchTerm);
    window.location.href = '/busca?t=' + encodedSearchTerm;
  };

  function encodeRFC5987ValueChars(str) {
    return encodeURIComponent(str)
      .replace(/['()]/g, escape)
      .replace(/\*/g, '%2A')
      .replace(/%(?:7C|60|5E)/g, unescape);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    redirectToSearchPage(busca);
  };

  return (
    filial > 0 &&
    <form className="header_search" onSubmit={handleSubmit}>
      <fieldset>
        <label htmlFor="search" id="searchlabel" className="input-search search-autocomplete">

          <input
            type="text"
            value={busca}
            onChange={handleSearchChange}
            style={{
              border: "1px solid #C9C9C9",
              borderRadius: "10px",
              width: "100%"
            }}
            placeholder={translations.SearchBar?.Input_Placeholder}

          />

          {searchResults.length > 0 && (
            <ul className="search-results">
              {searchResults.map((result, index) => (
                <li key={index} onClick={() => handleSelectResult(result)}>
                  {result.name}
                </li>
              ))}
            </ul>
          )}
          <span className="fas fa-search icon-submit" onClick={handleSubmit} style={{ cursor: 'pointer' }}></span>
        </label>

      </fieldset>
    </form>
  );
};

export default SearchBarResponsive;