import React, { useEffect, useState } from 'react';
import { scrollToTop, getItemSession } from '../utils';
import { fetchClient } from '../services/client-api';
import { useLanguage } from '../LanguageContext';


const Politics = () => {
    const [client, setClient] = useState([]);

    const { translations, changeLanguage } = useLanguage();

    useEffect(() => {
        scrollToTop();
    })
    
    const getDados = getItemSession('_dados');
    const dados = JSON.parse(getDados);


    useEffect(() => {
        fetchClient(dados.idCliente)
            .then(result => {
                setClient(result.data.Data.Dados[0])
            });
    }, [])
    
    return (
        <>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>{translations.Politics?.Politica_Garantias}</h1>
                    </div>
                    {client.Suframa &&
                        <ul className='list-unstyled '>
                            <h5><b>{translations.Politics?.Solicitud_Garantia}</b></h5>
                            <li style={{fontSize: '14px'}}>{translations.Politics?.Antes_solicitar} <a target='_blank' rel="noopener noreferrer" href={client.Suframa}><b>{translations.Politics?.click_iniciar}</b></a>.</li>
                        </ul>
                    }
                    <hr />
                </div>
                <div className="subtitulo-politics">
                <h4>{translations.Politics?.Recibir_Pedido}</h4>
                    <ol>
                        <li>
                        {translations.Politics?.Verifique_cantidad}
                        </li>
                        <li>
                        {translations.Politics?.Nuestras_cajas}
                        </li>
                        <li>
                        {translations.Politics?.Si_las_cajas}
                        </li>
                        <li>
                        {translations.Politics?.Para_reportar_cualquier}
                        </li>
                    </ol>
                    {translations.Politics?.Cualquier_anomalia}
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.Politics?.El_Plazo}</h4>
                   <ul>
                       <li>
                        {translations.Politics?.Si_el_Problema}
                       </li>
                       <li>
                       {translations.Politics?.Con_la_Cantidad}
                       </li>
                   </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.Politics?.Devolucion}</h4>
                    {translations.Politics?.Solo_se_aceptan}
                    <ul>
                        <li>
                        {translations.Politics?.Faltante_de_material}
                        </li>
                        <li>
                        {translations.Politics?.Material_sobrante}
                        </li>
                        <li>
                        {translations.Politics?.Producto_solicitad}
                        </li>
                        <li>
                        {translations.Politics?.Material_con_falta}
                        </li>
                        <li>
                        {translations.Politics?.Errores_de_descripcion}
                        </li>
                        <li>
                        {translations.Politics?.Materiales_en_garantia}
                        </li>
                    </ul>
                    {translations.Politics?.Para_que_devolucion}<br/><br/>
                    {translations.Politics?.Y_hacer_el}<br/><br/>
                    {translations.Politics?.En_este_caso}
                    {translations.Politics?.ver_proceso_anexo}
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.Politics?.Garantias}</h4>
                    {translations.Politics?.Antes_de_recibir}
                    <ul>
                        <li>
                        {translations.Politics?.El_plazo}
                        </li>
                        <li>
                        {translations.Politics?.Si_se_solicita}
                        </li>
                        <li>
                        {translations.Politics?.Si_se_deben}
                        </li>
                        <li>
                        {translations.Politics?.No_se_aceptan} 
                        </li>
                    </ul>                   
                </div>

                <div className="subtitulo-politics">
                    <h4>{translations.Politics?.garantia_de_Material}</h4>
                   <ol>
                       <li>
                       {translations.Politics?.Al_SAC}
                       </li>
                       <li>
                       {translations.Politics?.El_SA}
                       </li>
                       <li>
                       {translations.Politics?.mail_sus_documentos} <a>{translations.Politics?.email_shimanomexico}</a>
                       </li>
                       <li>
                       {translations.Politics?.El_departamento}
                       </li>
                       <li>
                       {translations.Politics?.En_el_momento}
                       </li>
                       <li>
                       {translations.Politics?.su_garantia}
                       </li>
                       <li>
                       {translations.Politics?.caso_de_rechazo}
                       </li>
                   </ol>
                </div>
                
            </section>
            
        </>
    );
}

export default Politics;