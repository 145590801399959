import React, { useState, useEffect, createElement } from 'react';
import { Link } from 'react-router-dom';
import { getItemSession } from '../../../utils';
import { fetchClient } from '../../../services/client-api';
import { useLanguage } from '../../../LanguageContext';

const DropdownUser = () => {
    const [nome, setNome] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [client, setClient] = useState(null);

    const { translations, changeLanguage } = useLanguage();
    const dados = JSON.parse(getItemSession('_dados'));

    useEffect(() => {
        if (dados) {
            setNome(dados.Nome);
            setCnpj(dados.Login);
            fetchClient(dados.idCliente)
                .then(result => {
                    setClient(result.data.Data.Dados[0]);
                })
                .catch(error => {
                    console.error('Error fetching client data:', error);
                });
        }
    }, []);

    const linkStyle = {
        textDecoration: 'none',
        color: 'inherit',
        display: 'block',
        padding: '8px 16px',
    };

    const listItemStyle = {
        listStyleType: 'none',
        alignItems: 'center',
    };

    const submenuStyle = {
        position: 'relative',
        alignItems: 'center',
    };

    const submenuDropdownStyle = {
        position: 'absolute',
        top: '0',
        left: '100%',
        marginTop: '3px',
        marginLeft: '-1px',
        display: 'none',
        borderRadius: '0.25rem',
        backgroundColor: '#F2F2F2',
        width: '250px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adicionando sombra
        alignItems: 'center',
        textAlign: 'center',
        padding: '16px 25px'
    };

    const dropdownMenuStyle = {
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adicionando sombra
    };

    return (
        <>
            {!dados?.ApenasVisualizacao ?
                <div className="dropdown dropdown_user">
                    <button className="btn btn--icon dropdown-toggle" type="button" id="UserDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="dropdown_user_img"><span className="fas fa-user"></span></span>
                        <span className="dropdown_user_msg">
                            <b>{nome}</b>
                            <small>{translations.DropdownUsers?.RFC} {cnpj}</small>
                        </span>
                        <span className="fas fa-chevron-down"></span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="UserDropdown">
                        <div className="dropdown-menu_cont">
                            <ul className="list-unstyled mb-0">
                                <li>
                                    {createElement('a', { href: '/minha-conta', className: 'dropdown-menu_link' }, translations.DropdownUsers?.Minha_Conta)}
                                </li>
                                <li>
                                    {createElement('a', { href: '/pedidos', className: 'dropdown-menu_link' }, translations.DropdownUsers?.Pedidos)}
                                </li>
                                <li>
                                    {createElement('a', { href: '/pendencias', className: 'dropdown-menu_link' }, translations.DropdownUsers?.Pendencias)}
                                </li>
                                {client?.Suframa &&
                                    <li>
                                        {createElement('a', { href:client.Suframa, target:'_blank', rel:"noopener noreferrer", className: 'dropdown-menu_link' }, translations.DropdownUsers?.Garantias)}
                                    </li>
                                }
                                <li style={{ ...submenuStyle, ...listItemStyle }}>
                                    <a href="#" className="dropdown-menu_link"
                                        onMouseEnter={e => e.currentTarget.nextSibling.style.display = 'block'}
                                        onMouseLeave={e => e.currentTarget.nextSibling.style.display = 'none'}>
                                        {translations.DropdownUsers?.Actualizacion_Dados}
                                    </a>
                                    <ul style={submenuDropdownStyle}
                                        onMouseEnter={e => e.currentTarget.style.display = 'block'}
                                        onMouseLeave={e => e.currentTarget.style.display = 'none'}>
                                        <li style={listItemStyle}>
                                            <a href="#" className="dropdown-menu_link" >{translations.DropdownUsers?.Fiscales}</a>
                                        </li>
                                        <li style={listItemStyle}>
                                            <a href="https://app.pipefy.com/public/form/b6DGjZE-" target="_blank" rel="noopener noreferrer" className="dropdown-menu_link" >{translations.DropdownUsers?.Direccion_Entrega}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="dropdown-menu_footer">
                            <Link to="/logout" className="dropdown-menu_link" style={linkStyle}>{translations.DropdownUsers?.logout}</Link>
                        </div>
                    </div>
                </div>
                :
                <div className="dropdown_user user-hitbox">
                    <Link className="btn btn--icon dropdown-toggle" type="button" id="UserDropdown" to="/logout">
                        <span className="dropdown_user_img"><span className="fas fa-user"></span></span>
                        <span className="dropdown_user_msg">
                            Login
                        </span>
                    </Link>
                </div>
            }
        </>
    );
}

export default DropdownUser;
