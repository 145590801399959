import React, { useState, useEffect, createElement } from 'react';
import { useLanguage } from '../LanguageContext';
// default


import { fetchSubjects, postContact } from '../services/contact-api';
import { scrollToTop } from '../utils';

const Contact = () => {
    const [inputs, setInputs] = useState({
        RazaoSocial: '',
        Responsavel: '',
        Telefone: '',
        Email: '',
        Assunto: '',
        Mensagem: ''
    });
    const [result, setResult] = useState(null);

    const [subjects, setSubjects] = useState([]);

    const { translations, changeLanguage } = useLanguage();

    useEffect(() => {
        fetchSubjects()
            .then(result => {
                setSubjects(result.data.Data)
            });
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        scrollToTop();

        const { RazaoSocial, Responsavel, Telefone, Email, Assunto, Mensagem } = inputs;

        postContact(RazaoSocial, Responsavel, Telefone, Email, Assunto, Mensagem)
            .then(result => {
                setResult({
                    success: true,
                    message: translations.Contact?.Sucesso_mensagem
                });
                setInputs({
                    RazaoSocial: '',
                    Responsavel: '',
                    Telefone: '',
                    Email: '',
                    Assunto: '',
                    Mensagem: ''
                })
            })
            .catch(() => {
                setResult({
                    success: false,
                    message: translations.Contact?.Erro_mensagem
                });
            })
    }

    const onInputChange = event => {
        const { name, value } = event.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    };

    return (
        <>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h2 className="titulo h2">{translations.Contact?.Fale_Conosco}</h2>
                    </div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-10 offset-1 fale-conosco-wrapper">
                        {result && (
                            <p className={`alert ${result.success ? 'alert-success' : 'alert-danger'}`} role="alert">
                                {result.message}
                            </p>
                        )}

                        <p className="fale-conosco--texto">{translations.Contact?.Preencha_formulario}</p>
                        <form className="form-fale-conosco" onSubmit={handleSubmit}>
                            <fieldset className="row">
                                <div className="col-12 col-lg-4">
                                    <label className="input-label" for="razao-social">{translations.Contact?.Razao_Social}</label>
                                    <input onChange={onInputChange} required value={inputs.RazaoSocial} type="text" className="input" id="razao-social" name="RazaoSocial" placeholder={translations.Contact?.Razao_Social_placeholder} />
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <label className="input-label" for="responsavel">{translations.Contact?.Responsavel}</label>
                                    <input onChange={onInputChange} required value={inputs.Responsavel} type="text" className="input" id="responsavel" name="Responsavel" placeholder={translations.Contact?.Responsavel_placeholder} />
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <label className="input-label" for="telefone">{translations.Contact?.Telefone}</label>
                                    <input onChange={onInputChange} required value={inputs.Telefone} type="text" className="input" id="telefone" name="Telefone" placeholder={translations.Contact?.Telefone_placeholder} />
                                </div>
                            </fieldset>
                            <fieldset className="row">
                                <div className="col-12 col-md-6">
                                    <label className="input-label" for="email">{translations.Contact?.Email} </label>
                                    <input onChange={onInputChange} required value={inputs.Email} type="email" className="input" id="email" name="Email" placeholder={translations.Contact?.Email_placeholder} />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="input-label" for="assunto">{translations.Contact?.Assunto}</label>
                                    <label for="assunto" className="select">
                                        <select onChange={onInputChange} required name="Assunto" id="assunto">
                                            <option value="">{translations.Contact?.Selecione}</option>
                                            {subjects.map((subject, index) => (
                                                <option key={`opt-${index}`} value={subject}>{subject}</option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </fieldset>
                            <fieldset className="row">
                                <div className="col-12">
                                    <label className="input-label" for="mensagem">{translations.Contact?.Mensagem}</label>
                                    <textarea onChange={onInputChange} required name="Mensagem" className="input textarea" id="mensagem" rows="3">{inputs.Mensagem}</textarea>
                                </div>
                            </fieldset>
                            <fieldset className="row">
                                <div className="col-12 col-md-5 col-lg-3">
                                    <button type="submit" className="btn btn--cinza btn--full">{translations.Contact?.Enviar}</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>

                <div className="sect-footer align-items-start">
                    {/* <a href="" className="btn btn--cinza btn--block btn-full btn--bold">Voltar</a> */}
                    {createElement('a', { href: '/', className: "btn btn--cinza btn--block btn-full btn--bold" }, "Regresar")}
                </div>
            </section>
            
        </>
    );
}

export default Contact;