import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setItemSession, getItemSession } from '../utils';
import Cookies from 'js-cookie';
import { useLanguage } from '../LanguageContext';

const Logout = () => {
    const history = useHistory();
    const { translations, changeLanguage } = useLanguage();

    Cookies.remove('Notifications');

    useEffect(() => {
        // Preserva a chave do idioma antes de limpar o localStorage
        const selectedLanguage = localStorage.getItem('language');

        if (window.sessionStorage.length > 0) {
            var filial = getItemSession('_filialSelecionada');
            window.sessionStorage.clear();
            window.localStorage.clear();

            // Restaura o idioma após limpar o localStorage
            if (selectedLanguage) {
                localStorage.setItem('language', selectedLanguage);
            }

            setItemSession('_filialSelecionada', filial);
            window.location.href = process.env.REACT_APP_URL_REDE_blink;
        } else {
            history.push("/");
        }
    }, [history]);

    return (
        <section className="conteudo_interno container">
            <div className="d-flex align-items-center">
                <strong className="mr-2">{translations.Logout?.Redireccionando}... </strong>
                <div className="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
            </div>
        </section>
    );
};

export default Logout;
