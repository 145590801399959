import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchAlterarIdioma } from './services/client-api';
import { getItemSession, setItemSession } from './utils';
import { fetchManufacturers } from './services/manufacturers-api';
import { fetchCategories, fetchCategoriesById } from './services/categories-api';
import { fetchVersion, fetchHomolog } from './services/system-api';
import { fetchCartItems } from './services/cart-api';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom'; // Assuming you're using React Router

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => localStorage.getItem('language') || process.env.REACT_APP_API_IDIOMA);
  const [translations, setTranslations] = useState({});
  const [cookies, setCookie] = useCookies(['language']);
  const history = useHistory(); // React Router hook

  const loadData = async (language) => {
    try {
      const response = await fetchAlterarIdioma(language);
      const novoToken = response.data.Data;

      if (novoToken) {
        setItemSession('_token', novoToken);
        setCookie('_token', novoToken, { path: '/' });
      }

      const [manufacturers, categories, version, homolog, cartItems, categoriesById] = await Promise.all([
        fetchManufacturers(novoToken),
        fetchCategories(novoToken),
        fetchVersion(novoToken),
        fetchHomolog(novoToken),
        fetchCartItems(novoToken),
        fetchCategoriesById(novoToken, 2, true, true)
      ]);

      setItemSession('_fornecedores', JSON.stringify(manufacturers.data.Data.Dados));
      setItemSession('_categorias', JSON.stringify(categories.data.Data.Dados) ?? []);
      setItemSession('blink_versao', process.env.REACT_APP_VERSION);
      setItemSession('blink_homolog', JSON.stringify(homolog.data.Data));
      setItemSession('_carrinho', JSON.stringify(cartItems.data.Data));
      setItemSession('_marcas', JSON.stringify(categoriesById.data.Data.Dados) ?? []);

      window.location.reload();
    } catch (error) {
      console.error('Error loading data:', error);
    }
  };

  useEffect(() => {
    // Use dynamic import to load translations
    import(`./locales/${language}/translation.json`)
      .then(data => setTranslations(data))
      .catch(error => console.error('Error loading translations:', error));
  }, [language]);

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    loadData(lang);
  };

  return (
    <LanguageContext.Provider value={{ translations, language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
