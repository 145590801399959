import React, { useState, useEffect, createElement } from 'react';
// default
import { Loader } from '../components/Loader/Loader';
import { formatMoney, disconnect, formatDateToView, setItemSession } from '../utils';
import { fetchInvoice, fetchInvoiceItems, fetchTracking, fetchExcel, fetchNF,fetchHistoricoNFArquivo } from '../services/invoices-api';
import { postDuplicateCart, fetchCartItems } from '../services/cart-api';
import { trackingIcons } from '../constants/'
import * as CartActions from '../store/actions/cart'
import { connect } from 'react-redux';
import { FaTimes, FaCheck, FaAdjust, FaFileExcel, FaFilePdf, FaSync, FaWindowClose } from 'react-icons/fa';
import { useLanguage } from '../LanguageContext';

const Swal = require('sweetalert2');

const InvoiceDetail = ({ match, refreshCartFn }) => {
    const [isLoading, setLoading] = useState(true);
    const [invoice, setInvoice] = useState([]);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [trackings, setTracking] = useState([]);
    const [notasFiscais, setNotasFiscais] = useState([]);

    const idInvoice = match.params.id;
    const { translations, changeLanguage } = useLanguage();

    useEffect(() => {
        fetchInvoice(idInvoice)
            .then(result => {
                setInvoice(result.data.Data.Dados[0])
                setLoading(false)
            })
            .catch(reject => {
                disconnect();
            })
    }, [])

    useEffect(() => {
        fetchInvoiceItems(idInvoice)
            .then(result => {
                if (result.data.Data) {
                    setInvoiceItems(result.data.Data.Dados)
                    setLoading(false)
                }
            })
        /* Deixado somente 1 disconnect */
        // .catch(reject => {
        //     disconnect();
        // })
    }, [])

    useEffect(() => {
        fetchTracking(idInvoice)
            .then(result => {
                if (result.data.Data) {
                    setTracking(result.data.Data.Dados)
                    setLoading(false)
                }
            })
        /* Deixado somente 1 disconnect */
        // .catch(reject => {
        //     disconnect();
        // })
    }, [])

    useEffect(() => {
        fetchNF(idInvoice)
            .then(result => {
                if (result.data.Data) {
                    setNotasFiscais(result.data.Data.Dados)
                }
            })
   
    }, [])

    const handleExcel = () => {
        fetchExcel(idInvoice)
            .then(result => {
                
                const data = result.data.Data.Arquivo;
                console.log(data)
                const arrayBuffer = base64ToArrayBuffer(data);
                createAndDownloadBlobFile(arrayBuffer, result.data.Data.NomeArquivo);
            })
            .catch(reject => {
                disconnect();
            })
    }
 
    

    const handleHistoricoNFArquivo = () => {
        fetchHistoricoNFArquivo(invoice.idNF)
            .then(response => {
                const data = response.data.Data.FileContents;
                const arrayBuffer = base64ToArrayBuffer(data);
                createAndDownloadBlobFile(arrayBuffer, idInvoice+'.pdf');
                
            })
            .catch(response => {
                alert('Factura no encontrada')
                // Tratar o erro, se necessário
            });
    }
    
   
    const handleDuplicateInvoice = (idInvoice) => {
        postDuplicateCart(idInvoice)
            .then(result => {
                if (result.data.Codigo === 200) {
                    fetchCartItems()
                        .then(result => {
                            if (result.data.Data.Dados.length > 0) {
                                setItemSession('_carrinho', JSON.stringify(result.data.Data))
                                refreshCartFn(result.data.Data);
                                window.location = '/carrinho';
                            }
                        })
                        .catch(reject => {
                            disconnect();
                        })
                } else {
                    console.debug('Error')
                }
            })
    }
    
    function base64ToArrayBuffer(base64) {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = filename;
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    const ItemStatusIcon = invoiceItem => {
        const faturada = invoiceItem.invoiceItem.QuantidadeFaturada;
        const pedida = invoiceItem.invoiceItem.QuantidadePedida;
        
        if (faturada === 0) {            
            return <span className="c-laranja"><FaTimes/></span>
        }

        if (faturada === pedida) {
            return <span className="c-roxo"><FaCheck/></span>
        }

        if (faturada < pedida) {
            return <span className="c-roxo"><FaAdjust/></span>
        }
    }
    
    const Tracking = () => {
        return trackings.map((tracking, index) => (
            <li key={`track-${index}`} className={tracking.Data ? "step step--active" : "step"}>
                <div className="step-icon"><span className={`icon ${trackingIcons[tracking.CodigoStatusPedido]}`}></span></div>
                <div className="step-conteudo">
                    <span className="step-data">{tracking.Data && formatDateToView(tracking.Data)}</span>
                    <h6 className="titulo h6">{tracking.Status}</h6>
                </div>
            </li>
        ))
    }

    return (
        <>            
            <section className="pedidos-detalhe container">
                <div className="sect-header">
                    <ul className="breadcrumb list-unstyled">
                        <li>
                            {/* <a href="index.html">Página Principal</a> */}
                            {createElement('a', { href: '/' }, translations.InvoiceDetail?.Pagina_Principal)}
                        </li>
                        <li>
                            {/* <a href="pedidos.html">Meus Pedidos</a> */}
                            {createElement('a', { href: '/pedidos' }, translations.DropdownUsers?.Pedidos)}
                        </li>
                        <li className="active">{translations.InvoiceDetail?.Detalhe_pedido}</li>
                    </ul>
                    <div className="sect-titulo">
                        <h2 className="titulo h2">{translations.InvoiceDetail?.Pedido} <span className="c-laranja">{invoice.NumeroPedidoERP}</span></h2>
                    </div>
                </div>
                <div className="row pedidos-detalhe--wrapper">
                    <div className="col-6 col-md-3 col-lg-2 pedidos-detalhe--item">
                        <span className="pedidos-detalhe--item-titulo">{translations.InvoiceDetail?.Status_pedido}:</span>
                        <p className="pedidos-detalhe--item-conteudo"><b>{invoice.Status}</b></p>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 pedidos-detalhe--item">
                        <span className="pedidos-detalhe--item-titulo">{translations.InvoiceDetail?.Valor_pedido}:</span>
                        <p className="pedidos-detalhe--item-conteudo"><b>$ {formatMoney(invoice.Valor, 2, '.', ',')}</b></p>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 pedidos-detalhe--item">
                        <span className="pedidos-detalhe--item-titulo">{translations.InvoiceDetail?.Condicion_Pago}</span>
                        <p className="pedidos-detalhe--item-conteudo"><b>{invoice.CondicaoPagamento}</b></p>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 col-xl-2 pedidos-detalhe--item">
                        <span className="pedidos-detalhe--item-titulo">{translations.InvoiceDetail?.Detalhe_pedido}:</span>
                        <p className="pedidos-detalhe--item-conteudo"><b>
                            {!invoice.DataEntrega
                                ? translations.InvoiceDetail?.En_Definicion
                                : formatDateToView(invoice.DataEntrega) + '-' + invoice.PeriodoEntrega}</b>
                        </p>
                    </div>
                    {/*<div className="col-lg-3 col-xl-4 pedidos-detalhe--item">
                        <button data-toggle="modal" data-target="#modalPedidosDetalhe" className="btn btn--lg btn--branco btn--full btn--bold"><span className="icon icon-bar-code"></span><span>Boleto de pagamento</span></button>
                    </div>*/}
                </div>
                <div className="row pedidos-detalhe--wrapper">
                    <div className="col-md-5 col-lg-4 col-xl-3 pedidos-detalhe--item">
                        <span className="pedidos-detalhe--item-titulo">{translations.InvoiceDetail?.Direccion_Entrega}:</span>
                        <p className="pedidos-detalhe--item-conteudo">{invoice.EnderecoEntrega}</p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 pedidos-detalhe--item">
                        <span className="pedidos-detalhe--item-titulo">{translations.InvoiceDetail?.Programacion_Entrega}:</span>
                        <p className="pedidos-detalhe--item-conteudo">{translations.InvoiceDetail?.Tiempo_cambios}</p>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 pedidos-detalhe--item">
                        <span className="pedidos-detalhe--item-titulo">{translations.InvoiceDetail?.Cierre_Compra}:</span>
                        <p className="pedidos-detalhe--item-conteudo"><b>{formatDateToView(invoice.DataEmissao)}</b></p>
                    </div>
                    
                </div>

                <div className="steps">
                    <ol className="steps-lista list-unstyled">
                        {!isLoading ? (
                            <Tracking />
                        ) : (
                                <Loader short="false" />
                            )}
                    </ol>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-6 col-lg-3">
                        <h2 className="titulo h2 mb-0">{translations.InvoiceDetail?.Itens_pedido}</h2>
                    </div>

                    <div className="col-md-6 col-lg-7 p-0">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-4 p-0">
                                <a onClick={handleExcel} className="btn btn--lg btn--branco btn--bold">
                                    <FaFileExcel />
                                    <span style={{fontSize: "0.75em", lineHeight: 1.2}}>{translations.InvoiceDetail?.Download_Excel}</span>
                                </a>
                            </div>

                            <div className="col-md-6 col-lg-5">
                                <button onClick={handleHistoricoNFArquivo} className="btn btn--lg btn--branco btn--bold">
                                    <FaFileExcel />
                                    <span style={{fontSize: "0.75em", lineHeight: 1.2}}>{translations.InvoiceDetail?.Download_Factura}</span>
                                </button>
                            </div>

                         
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 p-0">
                        <a onClick={() => { if (window.confirm('Deseja repetir esse pedido?')) handleDuplicateInvoice(invoice.idPedido) }} className="btn btn--lg btn--branco btn--bold">
                            <FaSync />
                            <span style={{fontSize: "0.75em", lineHeight: 1.2}}>{translations.InvoiceDetail?.Repetir_Pedido}</span>
                        </a>
                    </div>
                </div>

                <div className="tabela-overflow">
                    {!isLoading ? (
                        <table className="tabela tabela-listrada">
                            <thead>
                                <tr>
                                    <th width="40" className="text-center">{translations.InvoiceDetail?.Accion}</th>
                                    <th width="100" className="text-center">{translations.InvoiceDetail?.Cantidad_Atendido}</th>
                                    <th width="100" className="text-center">{translations.InvoiceDetail?.Cantidad}</th>
                                    <th>{translations.InvoiceDetail?.Descripcion}</th>
                                    <th>{translations.InvoiceDetail?.Accion}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceItems.map(invoiceItem =>
                                    <tr key={`invoice-item-${invoiceItem.Item}`}>
                                        <td className="text-center">
                                            <ItemStatusIcon invoiceItem={invoiceItem} />
                                        </td>
                                        <td className="text-center">{invoiceItem.QuantidadeFaturada}</td>
                                        <td className="text-center">{invoiceItem.QuantidadePedida}</td>
                                        <td>{invoiceItem.Descricao}</td>
                                        <td>
                                            {/* <a href="produtos-detalhe.html" className="link-texto link-texto--roxo font-weight-normal">Ver produto</a> */}
                                            {createElement('a', { href: `/produto/${invoiceItem.idProduto}`, target: "_self", className: "link-texto link-texto--roxo font-weight-normal" }, translations.InvoiceDetail?.Ver_Producto)}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (
                            <Loader short="false" />
                        )}
                </div>

                <div className="sect-footer align-items-start">
                    {createElement('a', { href: '/pedidos', className: "btn btn--cinza btn--block btn-full btn--bold" }, translations.InvoiceDetail?.Regresar)}
                </div>
            </section>
            

            {/* <!-- Modal Boleto --> */}
            <div className="modal modal-boleto fade" id="modalPedidosDetalhe" tabIndex="-1" role="dialog" aria-labelledby="modalPedidosDetalhe" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-header_container">
                                <img src="../img/img-modal.png" alt={translations.InvoiceDetail?.Alt_Img} />
                                <h2 className="titulo h2">{translations.InvoiceDetail?.boleto_disponivel_Rede_blink}</h2>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{}}>
                                <FaWindowClose/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ol className="lista-modal">
                                <li className="lista-modal_item">Acesse o Portal</li>
                                <li className="lista-modal_item">{translations.InvoiceDetail?.Entre_Item} <span>"{translations.InvoiceDetail?.Central_Compras}"</span> {translations.InvoiceDetail?.Menu}</li>
                                {/* <li className="lista-modal_item">Lorem Ipsum</li> */}
                                {/* <li className="lista-modal_item">Ipsum Lorem dorime</li> */}
                            </ol>
                        </div>
                        
                    </div>
                </div>
            </div>

            {/* <!-- Modal NF --> */}
            <div className="modal modal-boleto modal-nota fade" id="modalNotaFiscal" tabIndex="-1" role="dialog" aria-labelledby="modalNotaFiscal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-header_container">
                                <h2 className="titulo h2">{translations.InvoiceDetail?.Notas_Fiscais}</h2>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="icon icon-times"></i>
                            </button>
                        </div>
                        <div className="modal-body px-0">
                            <table className="table table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th>{translations.InvoiceDetail?.Numero_Factura}</th>
                                        <th>{translations.InvoiceDetail?.Data}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {notasFiscais.map(notaFiscal => (
                                        <tr>
                                            <td>{notaFiscal.NumeroNF}</td>
                                            <td>{formatDateToView(notaFiscal.DataEmissao)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button data-dismiss="modal" aria-label="Close" className="btn btn--lg btn--roxo btn--bold">{translations.InvoiceDetail?.Fechar}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(InvoiceDetail);