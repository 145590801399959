import React, { useEffect, useState } from 'react';
import FooterHomolog from './Homolog';
import { getItemSession } from '../../utils';
import TipBarFooter from './TipBarFooter';
import WhatsappFooter from './WhatsappFooter';
import { useLanguage } from '../../LanguageContext';

export default function Footer() {
    const [isHomolog, setIsHomolog] = useState(false);
    const [versionApi, setVersionApi] = useState('');
    const { translations, changeLanguage } = useLanguage();

    useEffect(() => {
        const dadosHomolog = getItemSession('blink_homolog');
        setIsHomolog(dadosHomolog);

        setVersionApi(process.env.REACT_APP_VERSION);
    }, [])

    return (
        <footer className="footer">
            <TipBarFooter />
            <div className="container">
                <div className="contato-container">
                    <nav className="nav-footer">
                    </nav>
                </div>
                <div className="copyright">
                    <div className="copyright--container">
                        <p className="footer--texto">@{new Date().getFullYear()} {translations.Footer?.Todos_los}</p>
                        <div className="copyright--desenv">
                            <p className="footer--texto arial-bold mb-lg-0">{translations.Footer?.Desarrollado_por_Blink}</p>
                            <p className="footer--texto arial-bold mb-0">Blink WebSales / Version: {process.env.REACT_APP_VERSION} / API {versionApi}</p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHomolog 
                flag={isHomolog}
                version={process.env.REACT_APP_VERSION}
                versionApi={versionApi}
            />
            <WhatsappFooter flag={isHomolog} />
        </footer>
    )
}