import React, { createElement } from 'react';
// import { Link } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';

const NotFound = () => {
    const { translations, changeLanguage } = useLanguage();
    return (
        <>
            <section className="conteudo_interno container">
                <div>
                    <h1 className="display-4">Oops!<br />{translations.NotFound?.Pagina_no_encontrada}</h1>
                    {/* <Link to="/" class="btn btn--cinza btn--block btn--full btn--bold">Ir para página inicial</Link> */}
                    {createElement('a', { href: "/", className: "mt-5 btn btn--cinza btn--block btn--full btn--bold" }, translations.NotFound?.Pagina_Inicio)}
                </div>
            </section>
        </>
    )
}

export default NotFound;