import React, { useState, useEffect } from 'react';
import { useLanguage } from '../LanguageContext';
// default


import { scrollToTop } from '../utils';
import styles from '../scss/history.css';
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from 'react-icons/fa';
import imgHistory from './../img/Historia.png';


const CompanyHistory = () => {
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        scrollToTop();
    });

    const imgModal = (e) => {
        setIsOpen(false)


    };
    const { translations, changeLanguage } = useLanguage();

    let yrs = ['1921', '1981', '1991', '2001', '2011', '2021'];

    const showHistory = (e) => {
        for (let index = 0; index < yrs.length; index++) {
            var ocul = document.getElementById(yrs[index]);
            if (yrs[index] === e.target.value) {
                ocul.style.display = "block";
            } else {
                if (ocul.style.display === "block") {
                    ocul.style.display = "none";
                }
            }
        }
    }


    return (
        <>
            <Modal show={isOpen} id="myModal2" className="modal modal-boleto fade">
                <Modal.Header>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={imgModal}>
                        <FaWindowClose />
                    </button>
                </Modal.Header>
                <Modal.Body id='img012'>
                    <img alt='Shimano Historia' src={imgHistory} />
                </Modal.Body>
            </Modal>

            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>{translations.CompanyHistory?.Historia_de_Shimano}</h1>                    </div>
                    <hr />
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="1921" onClick={showHistory} > 1921 -1980 </button>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="1981" onClick={showHistory} > 1981 -1990 </button>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="1991" onClick={showHistory} > 1991 -2000 </button>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="2001" onClick={showHistory} > 2001 -2010 </button>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="2011" onClick={showHistory} > 2011 -2020 </button>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="2021" onClick={showHistory} > 2021 </button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {/* 1921 */}
                <div className="row" id="1921" style={{ display: 'block' }}>
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1921</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shozaburo_Shimano_establece_Shimano_Iron_Works_}</h3>
                                <p className="text_history">
                                {translations.CompanyHistory?.En_febrero_de_1921_abrio_las_forjas}
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1921/1921.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1922</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Comienza_la_produccion_del_pinon_libre_para}</h3>                                <p className="text_history">
                                {translations.CompanyHistory?.Para_el_momento_que_Shozaburo_funda_su}
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1921/1921_2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1940</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_nombre_de_la_compania_cambia_a}</h3>
                                <p className="text_history">
                                {translations.CompanyHistory?.En_1940_Shimano_Iron_Works_Limited_fue}
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1940/1940.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shozaburo_Shimano_se_convierte_en_el_primer}</h3>
                                <p className="text_history">
                                {translations.CompanyHistory?.En_enero_15_de_1940_la_primera}
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1951</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_compania_cambia_de_nombre_a_Shimano}</h3>                                <p className="text_history">
                                {translations.CompanyHistory?.En_febrero_de_1951_Shimano_Iron_Works}
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1956</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_produccion_del_primer_cambio_de_velocidad}</h3> 
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1957</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_produccion_de_primer_cambio_de_velocidad}</h3>                                
                                <p className="text_history">

                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1957/1957.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_investigacion_de_forja_en_frio_comienza_}</h3>                                <p className="text_history">
                                {translations.CompanyHistory?.Shimano_industrial_comienza_a_investigar_sobre_la}
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1958</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Fallece_Shozaburo_Shimano} </h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.En_septiembre_de_1958_fallece_Shozaburo_Shimano_}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shozo_Shimano_se_convierte_en_el_segundo}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1958/1958.png" alt='Shozo Shimano' className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1961</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Por_primera_vez_se_muestra_el_centro}</h3>                                <p className="text_history">
                                {translations.CompanyHistory?.Se_muestra_en_el_Show_Internacional_Juguetes}
                                </p>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1965</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_corporacion_Shimano_de_America_se_establece}</h3>                                <p className="text_history">
                                {translations.CompanyHistory?.La_continua_existencia_de_Shimano_en_el} 
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1965/1965.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1970</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Yamaguchi_Corporation_Limited_actual_fabrica_de}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.El_sistema_de_produccion_ha_sido_reforzado}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1970/1970.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_division_de_equipo_para_pesca}</h3>
                                <p className="text_history">
                                {translations.CompanyHistory?.La_division_de_equipo_para_pesca_es} 
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1971</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_serie_DUX_de_rieles_giratorios_se}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1971/1971.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1972</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Europa_GMBH_es_establecida_de_Dusseldorf}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_acciones_de_Shimano_son_listadas_en}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1973</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Racing_Team_es_lanzado_}</h3>                                <p className="text_history">
                                {translations.CompanyHistory?.En_adicion_al_activo_patrocinio_del_equipo}
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Singapur_Pte__Ltd__es_establecido_}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Es_establecida_la_primer_fabrica_exterior_en}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1973/1973-1.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_lista_de_acciones_sube_al_segundo}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_lista_de_valores_sube_a_la}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_serie_de_componentes_para_carreras_DURA_ACE}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1973/1973-2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1974</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Positron_es_lanzado_al_mercado_}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Esta_equipado_con_el_sistema_positron_que}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1976</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_series_DURA_ACE_10_son_lanzadas_al}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1978</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_series_Bantam_de_carretes_son_lazadas}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Un_carrete_ultraligero_de_precision_enfocado_a}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_serie_DURA_ACE_EX_7200_es_introducida}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1979</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_Shimano_American_Corporation_es_transferida_a}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1980</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_serie_DURA_ACE_ax_7300_es_lanzada}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Shimano_introduce_la_serie_DURA_ACE_ax_7300}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                </div>

                {/* 1981 */}
                <div className="row" style={{ display: 'none' }} id="1981">
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1981</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Kumamoto_equipo_para_pesca_es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_acciones_de_Dunphy_Holding_Pty_Ltd}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1982</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_primeras_series_SHIMANO_105_son_lanzadas}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_primeras_series_DEORE_XT_son_lanzadas}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1983</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Canada_Ltd__es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_serie_BB_X_son_lanzadas_al_mercado_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.SHIMANO_600EX_es_lanzado_al_mercado_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1984</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_primer_Shimano_Greenpia_Road_actualmente_carrera}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Shimano_inicia_la_Greenpia_Road_la_cual}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1984/1984-2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_copa_Shimano_Japon_es_llevada_a}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Shimano_inicia_la_Shimano_Japan_Cup_es}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1984/1984.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_series_DURA_ACE_7400_equipadas_con_el}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.La_funcion_remarcable_del_DURA_ACE_7400_es}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1985</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Europa_es_transferido_a_Hilden_al}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_series_Titanos_de_carretes_con_una}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Titanos_utiliza_una_avanzada_composicion_la_cual}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1986</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_American_Corporation_se_une_con_Shimano}</h3>                                <p className="text_history">
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_nueva_serie_Deore_XT_se_lanza}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Los_nuevos_componentes_de_Deore_XT_con}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_serie_Sante_esta_en_el_mercado_}</h3>                                <p className="text_history">
                                {translations.CompanyHistory?.El_concepto_basico_del_desarrollo_de_Sante}
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_series_de_SHIMANO_105_con_SLR}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Las_nuevas_series_de_Shimano_105_incorporan}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1987</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_serie_SHIMANO_600_Ultegra_es_lanzada}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Esta_serie_incorpora_todo_el_grupo_DURA_ACE}                                        </p>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1988</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Andy_Hampsten_Equipo_Seven_Eleven_gana_el_Giro}</h3>                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_U_K__Ltd__es_establecido_comienza_la}</h3>                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_FROM_el_cual_es_un_sistema}</h3>                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_canas_telescopicas_Fine_Carbon_a_Tokei}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.La_caracteristica_unica_de_la_cana_telescopica}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Los_zapatos_de_carreras_SH_R100_son_lanzados}</h3>                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1989</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_Centro_de_Distribucion_Shimano_Rinkai_es}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1989/1989.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Benelux_B_V__y_Shimano_Europe_Fishing}</h3>                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.STI_Shimano_Total_Integration_palanca_de_cambio}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.El_sistema_de_disparo_rapido_el_cual}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Nexus_es_lanzado_como_una_nueva_marca}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Shimano_comienza_con_el_equipo_de_pesca}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1990</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Components_Malasia_Sdn__Bhd_es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1990/1990.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Italy_Fishing_Sdn__Bhd__establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Pedaling_Dynamics_SPD_es_lanzado_al}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.La_linea_de_zapados_y_pedales_llamados}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.DURA_ACE_7400_series_con_control_de_palanca}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.El_control_de_palanca_dual_incorpora_un}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>


                </div>

                {/* 1991 */}
                <div className="row" style={{ display: 'none' }} id="1991">
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1991</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_nombre_corporativo_es_cambiado_de_Shimano}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Batam_PT_es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1991/1991.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Los_componentes_para_bicicleta_de_montana_XTR}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.XTR_es_la_primera_linea_de_componentes}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_primera_Shimano_RE_EX_CROSS_COUNTRY_es}</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.En_el_aniversario_numero_70_el_lema}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1992</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Keizo_Shimano_se_convierte_en_el_tercer}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1992/1992.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_Cycle_Center_Museo_de_la_Bicicleta}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.En_coordinacion_con_la_Ciudad_de_Sakai}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1992/1992-2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Kunshan_Bicycle_Components_Co__Ltd__es}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_primer_modelo_de_STELLA_es_lanzado}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1992/1992-3.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Australia_Pty__Ltd__es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1993</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.DURA_ACE_7410_es_lanzado_al_mercado_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1995</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Yoshizo_Shimano_se_convierte_en_el_cuarto}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1995/1995.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_modelo_1995_de_STELLA_es_lanzado}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Equipado_con_SHIP_Smooth_and_High_Power}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1996</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.DURA_ACE_7700_es_lanzado_al_mercado_}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.La_serie_7700_es_desarrollada_despues_de}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1997</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.G_Loomis_Inc_Se_integra_al_equipo_Shimano_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Auto_D_es_introducido_al_mercado_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1998</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_modelo_de_STELLA_1998_es_introducido}</h3>                                <p className="text_history">
                                {translations.CompanyHistory?.Es_el_tercer_modelo_fue_desarrollado_con}
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_tienda_de_golf_Aifie_es_inaugurada_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_modelo_del_25_aniversario_de_DURA_ACE}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Esta_serie_de_DURA_ACE_especial_fue_lanzado}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_HB_system_para_snowboards_es_introducido}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_sistema_Fligthdeck_es_introducido_al_mercado_}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.La_instalacion_de_un_switch_de_operacion}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1999</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_primera_Carrera_de_Ruta_Shimano_Motegi}</h3>                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_set_de_ruedos_para_bicicletas_de_ruta_WH_7700_es_lanzado}</h3>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2000</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_set_de_ruedos_para_bicicletas_de}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_STELLA_modelo_Millenium_es_lanzado_al}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.El_STELLA_edicion_Millenium_el_cual_se}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>
                </div>

                {/* 2001 */}
                <div className="row" style={{ display: 'none' }} id="2001">
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2001</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Yozo_Shimano_se_convierte_en_el_quinto}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2001/2001.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_modelo_2001_de_STELLA_es_lanzado}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Shimano_continua_el_desarrollo_del_Sistema_de}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Shanghai_Bicycle_Components_Co__Ltd_es}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2001/2001-2.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Republica_Checa_sro_es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Francia_Composants_Cycles_S_A_S_es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2002</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Taiwan_Co__Ltd_es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2003</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Kunshan_fishing_Tackle_Co__Ltd__es}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2003/2003.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Tianjin_Bicycle_Components_Co__Ltd__es}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2003/2003-2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2004</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_modelo_2004_de_STELLA_es_introducido}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.El_manillar_de_una_sola_pieza_de}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1995/1995.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_serie_DURA_ACE_7800_es_introducida_al}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Los_componentes_del_DURA_ACE_7800_fueron_desarrollados}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2005</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Nordic_Cycle_AB_Shimano_Nordic_Cycle}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2006</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Lianyungang_Industrial_Co__Ltd__es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.ACCU_3D_highly_functional_Cycling_Wear_es_lanzado_al} </h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2007</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Latin_America_Representacao_Comerial_Ltda_es}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.R_P_Pawlak_Sp__Z_o__o__es}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_modelo_2007_de_STELLA_es_introducido}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.El_nuevo_modelo_que_incorpora_el_carrete}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2008</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_acciones_de_Innovative_Textiles_Inc__son}</h3>                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_acciones_de_DashAmerica_inc__Pearl_Izumi}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_linea_DURA_ACE_7900_es_lanzada_al}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Los_componentes_de_la_serie_7900_fueron}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2009</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_division_de_Lifestyle_Gear_es_lanzada}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2009/2009.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Rinkai_Distribution_Center_cambia_su_nombre}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Comienza_el_marketing_del_equipamiento_de_canotaje}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2009/2009-2.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3></h3>
                                <p className="text_history">
                                    {translations.CompanyHistory?.Una_nueva_tecnologia_llamada_inteligencia_digital_integrada}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2009/2009-3.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2010</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.XEFO_es_introducido_al_mercado_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2010/2010.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_linea_XTR_que_incorpora_el_Dyna_sys}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2010/2010-2.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_modelo_2010_de_STELLA_entra_al}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.El_modelo_2010_incorporaba_el_sistema_de}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2010/2010-3.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_STEPS_el_primer_componente_para_la}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                </div>

                {/* 2011 */}
                <div className="row" style={{ display: 'none' }} id="2011">
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2011</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Camboya_Co__Ltd__es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_Consulado_General_de_la_Republica_de}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2012</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_series_DURA_ACE_9000_son_lazadas_al}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Las_series_DURA_ACE_9000_son_completamente_repensadas}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2012/2012.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2013</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Filipinas_Inc__es_establecido_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2014</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_modelo_STELLA_2014_es_lanzado_}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.La_tecnologia_de_micromodulo_de_transmision_de}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2014/2014-2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_series_XTR_M9000_XTR_M9020_son}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2014/2014-1.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_series_XTR_Di2_M9050_son_lanzadas}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_Shimano_Cycling_World_es_abierto_en}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Es_el_primer_mundo_interactivo_y_de}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2014/2014-3.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_primera_Sport_Camera_de_Shimano_es}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    {translations.CompanyHistory?.Shimano_Menat_Spor_Etkinlikleri_Spor_Malzemeleri_ve}                                </h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    {translations.CompanyHistory?.Shimano_Bisiklet_Parca_ve_Ekipmanlari_Satis_Servis}                                </h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    {translations.CompanyHistory?.Shimano_Italy_Bicycle_Components_S_R_l_es_establecido}                                </h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2015</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    {translations.CompanyHistory?.HAGANE_concept_es_lanzado_al_mercado_}                                </h3>
                                <p className="text_history">
                                    {translations.CompanyHistory?.HAGANE_es_el_concepto_de_Shimano_de}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2015/2015-1.jpg" className="img-fluid img-thumbnail" />
                                <img src="https://www.shimano.com/jp/img/company/history/2015/2015-2.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    {translations.CompanyHistory?.Shimano_Uruguay_es_establecida_}                                </h3>

                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2015/2015-3.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    {translations.CompanyHistory?.Aniversario_50_de_Shimano_American_Co_}                                </h3>

                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2016</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Las_series_DURA_ACE_R9100_componentes_de_carrera}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2016/2016-1.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.La_Fabrica_Inteligente_Shimano_Shimonoseki_SSF_IP_es}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2016/2016-2.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_Centro_de_Logistica_Rinkai_RLC_es}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2016/2016-3.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2017</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Square abre en el GRAND FRONT de Osaka</h3>
                                <p className="text_history">
                                    {translations.CompanyHistory?.Shimano_Square_abre_en_el_GRAND_FRONT}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2017/2017.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2018</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Series_XTR_M9100_salen_al_mercado_}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Persiguiendo_la_demanda_especifica_para_cada_categoria}                                </p>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_modelo_STELLA_2018_es_lanzado_}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.El_nuevo_STELLA_de_Shimano_es_hasta}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Argentina_S_A_U__es_establecido_en_Argentina_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2019</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Experience_Center_abre_en_Valkenburg_en}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Una_exhibicion_basada_en_la_experiencia_del}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/locations/europe/eu26.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_primer_grupo_de_componentes_para_GRAVEL}</h3>                                <p className="text_history">
                                    {translations.CompanyHistory?.Los_componentes_para_ruta_larga_de_gravel}                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2020</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.El_centro_de_tecnologia_e_innovacion_de}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_IBERIA_S__L__es_establecido}</h3>                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                </div>

                {/* 2021 */}
                <div className="row" style={{ display: 'none' }} id="2021">
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2021</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_celebra_su_aniversario_numero_100_}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2021/logo_shimano.svg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Taizo_Shimano_se_convierte_en_el_sexto}</h3>                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2021/President.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>{translations.CompanyHistory?.Shimano_Mexico_Bike___Fishing_es_establecido_}</h3>                                <p className="text_history">
                                {translations.CompanyHistory?.Shimano_Mexico_Bike___Fishing_es_establecido}
                                </p>
                                <p className="text_history">
                                    {translations.CompanyHistory?.Nacida_del_excelente_modelo_de_negocios_aplicado}                                </p>
                                <p className="text_history">
                                    {translations.CompanyHistory?.Isapa_y_LM_Bike_son_los_lideres}                                </p>
                                <p className="text_history">
                                    {translations.CompanyHistory?.Dado_al_excelente_resultado_que_se_tiene}                                </p>

                            </div>
                            <div className="col-md-4 text-center">
                                <img src="./img/fachada.jpeg" className="img-fluid img-thumbnail" />
                                <img src="./img/socios.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <br />
            <br />
            <br />


        </>
    );
}

export default CompanyHistory;