import React, { useEffect, useState } from 'react';
import Logo from './Logo/Logo';
import Search from './SearchBar/SearchBar';
import DropdownUser from './DropdownUser/DropdownUser';
import DropdownCart from './DropdownCart/DropdownCart';
import Change from './ChangeButton/ChangeButton';
import NavBar from '../NavBar';
import HeaderResponsive from '../HeaderResponsive';
import NavBarResponsive from '../NavBarResponsive';
import '../../scss/common.scss';
import { getItemSession } from '../../utils';
import LogoSSC from './ButtonSSC/ButtonSSC';
import LogoHome from './Logo/LogoHome';
import DropDownLanguage from './DropdownLanguage/DropDownLanguage';

export default function Header() {
  const dados = JSON.parse(getItemSession('_dados'));
  const unicaFilial = getItemSession('_unicaFilial') === 'true';
  const [classname, setClassName] = useState('');

  useEffect(() => {
    if(unicaFilial)
    {
      setClassName('usuario-logado-ssc')
    } else {
      setClassName('')
  
    }
  },[unicaFilial])


  return (
    <>
      <header className="header desktop">
        <div className="container header__topo d-none d-lg-flex">
        <div className="col-2" id='col-logo'>
            <Logo />
          </div>  
          <div className="row align-items-center col-logo-home" id="row-align">
            <LogoHome />
          </div>           
          {!unicaFilial &&
            <div className="row align-items-center col-filiais" id="row-align">
                <Change/>
            </div>
          }
          <div className='col-4' id='col-search'>
            <Search/>
          </div>
          <div className="col-1" id="col-ling">
            <DropDownLanguage tipe={true}/>
          </div>
          <div className="col-2" id="col-user">
            <DropdownUser/>
          </div>
          {!dados?.ApenasVisualizacao &&
            <div className="col-2" id="col-cart">
              <DropdownCart/>
            </div>
          } 
          <div className={`col-2 ${classname}`} id="col-ssc">
            <LogoSSC/>
          </div>
        </div>
        <HeaderResponsive/>
        <NavBar/>
      </header>
      <NavBarResponsive/>
    </>
  );
}
