import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import { CookiesProvider } from "react-cookie";
import { LanguageProvider } from './LanguageContext';

ReactDOM.render(
  <React.StrictMode key={`main-strictmode-key`}>
    <Provider store={store} key={`main-provider-key`}>
      <CookiesProvider key={`main-provider-key`}>
        <LanguageProvider>
          <App key={`main-app-key`} />
        </LanguageProvider>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

var className = document.getElementsByClassName("img-card-produto");

var myFunction = function() {
  alert('hola');
};

for (var i = 0; i < className.length; i++) {
    className[i].addEventListener('click', myFunction, false);
}