import React, { useState, useEffect } from 'react';

import { fetchFiliais, fetchMudarFilial } from '../../../services/client-api';
import { getItemSession } from '../../../utils';
import { fetchManufacturers } from '../../../services/manufacturers-api';
import { fetchCategories, fetchCategoriesById } from '../../../services/categories-api';
import { fetchVersion, fetchHomolog } from '../../../services/system-api';
import { fetchCartItems } from '../../../services/cart-api';
import { setItemSession } from '../../../utils';
import { useCookies } from 'react-cookie';
import { useLanguage } from '../../../LanguageContext';


const Change = () => {
    const [filial1, setFilial1] = React.useState('');
    const [filial2, setFilial2] = React.useState('');
    const [filiais, setFiliais] = React.useState([]);
    const [cookies, setCookie] = useCookies([]);

    const { translations, changeLanguage } = useLanguage();

    var token = getItemSession('_token')
    if (!token) {
        window.location.href = "/login_vitrine"
    }

    const fetchFiliaisData = async () => {
        try {
          const response = await fetchFiliais(token);
          if (response?.data?.Data?.Dados.length > 0) {
            setFiliais(response.data.Data.Dados);
          }
        } catch (error) {
          console.error('Erro ao buscar filiais:', error);
        }
      };
    
      useEffect(() => {
        if (token) {
          fetchFiliaisData();
        }
      }, [token]);

    React.useEffect(() => {
        if (filiais.length > 0) {
            let f1 = filiais[0].idFilial.toString();
            let f2 = filiais[1].idFilial.toString();
            setFilial1(f1); //2 => Ciclismo
            setFilial2(f2); //6 => Pesca 
        }
    }, [filiais])

    const filialBike = async () => {
        loadData(filial1);
    }

    const filialFishing = async () => {
        loadData(filial2);
    }

    const loadData = (idFilial) => {
        fetchMudarFilial(idFilial).then(r => {
            setItemSession('_filialSelecionada', idFilial);
            setCookie('_filialSelecionada', idFilial, { path: '/', expires: (new Date(Date.now())), maxAge: 3650 });

            const novoToken = r.data.Data;
            if (novoToken != null) {
                token = novoToken;
                setItemSession('_token', token);
            }
            Promise.all([
                fetchManufacturers(token),
                fetchCategories(token),
                fetchVersion(token),
                fetchHomolog(token),
                fetchCartItems(token),
                fetchCategoriesById(token, 2, true, true)
            ])
                .then(resultFetch => {
                    setItemSession('_fornecedores', JSON.stringify(resultFetch[0].data.Data.Dados));
                    setItemSession('_categorias', JSON.stringify(resultFetch[1].data.Data.Dados) ?? [])
                    setItemSession('blink_versao', process.env.REACT_APP_VERSION)
                    setItemSession('blink_homolog', JSON.stringify(resultFetch[3].data.Data))
                    setItemSession('_carrinho', JSON.stringify(resultFetch[4].data.Data))
                    setItemSession('_marcas', JSON.stringify(resultFetch[5].data.Data.Dados) ?? [])
                    window.location.href = process.env.REACT_APP_BASE_URL
                })
        })
    }

    return (
        <div className='changeContainer'>
            <button onClick={filialBike} className='buttonCycling'>
                <p>{translations.Header?.Ciclismo}</p>
            </button>

            <button onClick={filialFishing} className='buttonFishing'>
                <p>{translations.Header?.Pesca}</p>
            </button>
            <a className='btn-corporativo-home' target="_blank" rel="noopener noreferrer" href='https://www.shimano.com/en/'>
                <p>{translations.Header?.Corporativo}</p>
            </a>
        </div>
    )
}

export default Change;