import React, { createElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';

const Unauthorized = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { translations } = useLanguage();

    function clearBrowserData() {
        // Limpar cookies
        document.cookie.split(";").forEach((cookie) => {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        });

        // Limpar localStorage e sessionStorage
        localStorage.clear();
        sessionStorage.clear();
    }

    function hendlerVoltarHome() {     
        setLoading(true);  
        clearBrowserData(); // Limpa os dados do navegador
        setTimeout(() => {
            history.push("/");
        }, 4000);
    }

    return (
        <>
            <section className="conteudo_interno container">
                <div className="text-center">
                    <h1 className="display-4">Oops!</h1>
                    <p>{translations.Unauthorized?.produjo_error}</p>
                    <p>{translations.Unauthorized?.sesion_no_coinciden}</p>
                    <div className="btnUnauthorized">
                        {createElement('a', { href: "/login", className: "mt-5 btn btn--cinza btn--block btn--full btn--bold" }, translations.Unauthorized?.Intentar_nuevamente)}
                    </div>
                    <div className="btnUnauthorized">
                        <button className="mt-5 btn btn--cinza btn--block btn--full btn--bold" onClick={hendlerVoltarHome}>
                            {loading && <i className="fa fa-refresh fa-spin ml-2"></i>}
                            {translations.Unauthorized?.Volver_tienda}
                        </button>                        
                    </div>
                </div>
            </section>
        </>
    );
}

export default Unauthorized;
