import React, { createElement, useEffect, useState } from 'react';
import ProductCard from '../Product/ProductCard';
import { useLanguage } from '../../LanguageContext';
import { fetchProductsByTagHome } from '../../services/products-api';
import OwlCarousel from 'react-owl-carousel';

const options = {
    loop: true,
    items: 4,
    nav: true,
    autoplay: true,
    autoplayHoverPause: true,
    navText: ['', ''],
    navClass: ['nav-btn prev-slide', 'nav-btn next-slide'],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,
        }
    },
};

const Release = ({ destaque }) => {
    const { translations } = useLanguage();
    const [Vitrine, setVitrine] = useState([]);
    

    useEffect(() => {
        fetchProductsByTagHome(destaque.NomeOriginal, 6)
            .then(result => {
                if (result.data.Codigo === 200 && result.data.Data) {
                    setVitrine(result.data.Data.Dados);
                    console.log(result.data.Data.Dados);
                }
            });
    }, [destaque.NomeOriginal]);

    const language = localStorage.getItem('language') || 'es-MX';

    const getNomeByLanguage = () => {
        switch (language) {
            case 'es-MX':
                return destaque.NomeEspanholMX || destaque.NomeAlterado;
            case 'en-US':
                return destaque.NomeInglesUS || destaque.NomeAlterado;
            default:
                return destaque.NomeAlterado;
        }
    };

    return (
        Vitrine.length > 0 && (
            <>
                <div className="justify-content-between align-items-center div_titulo">
                    <h4 className="titulo h4 titulo_new">{getNomeByLanguage()}</h4>
                    {createElement('a', { href: "/destaque/" + encodeURIComponent(destaque.NomeOriginal), className: "link-texto btn_view text-decoration-none mr-md-3" }, translations.BestSeller?.Ver_Todo)}
                </div>
                <div className="overflow-auto">
                    <div className="cards cards-produto cards-produto-width-4 d-flex justify-content-between align-items-center ddd">
                        {Vitrine.length > 0 && (
                            <OwlCarousel className="owl-theme" {...options}>
                                {Vitrine.map(product => (
                                    <ProductCard
                                        key={`release-card-${product.idProduto}`}
                                        product={product}
                                        origin={destaque.NomeOriginal}
                                    />
                                ))}
                            </OwlCarousel>
                        )}
                    </div>
                </div>
            </>
        )
    );
};

export default Release;
