import React, {  useEffect  } from 'react';
// default
import { scrollToTop } from '../utils';
import { useLanguage } from '../LanguageContext';

const Politics = () => {

    useEffect(() => {
        scrollToTop();
    })
    const { translations, changeLanguage } = useLanguage();
    
    return (
        <>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>{translations.PoliticsComercial?.Politica_Comercial}</h1>
                    </div>
                    <hr />
                </div>
                <div className="subtitulo-politics">
                <h4>{translations.PoliticsComercial?.OBJETIVO}</h4>
                {translations.PoliticsComercial?.Este_documento}
                </div>
                <div className="subtitulo-politics">
                <span>{translations.PoliticsComercial?.Terminos_de_pago}</span><br/><br/>
                {translations.PoliticsComercial?.Se_tomara}
                <ul>
                    <li>
                    <b>{translations.PoliticsComercial?.Contato}</b> {translations.PoliticsComercial?.plazo_aplicara}
                    </li>
                    <li>
                    <b>{translations.PoliticsComercial?.dias30}</b> {translations.PoliticsComercial?.Clientes_que_tengan}
                    </li>
                    <li>
                    <b>{translations.PoliticsComercial?.dias60}</b> {translations.PoliticsComercial?.Clientes_historicamente}
                    </li>
                    <li>
                    <b>{translations.PoliticsComercial?.dias153045}</b> {translations.PoliticsComercial?.Clientes_plazo30}
                    </li>
                    <li>
                    <b>{translations.PoliticsComercial?.dias306090}</b> {translations.PoliticsComercial?.Clientes_plazo60}
                    </li>
                </ul>
                <div className="subtitulo-politics">
                {translations.PoliticsComercial?.Los_clientes}<br/><br/>
                {translations.PoliticsComercial?.Lo_clientes_con_retraso}<b>{translations.PoliticsComercial?.semaforo_rojo}</b>{translations.PoliticsComercial?.dando_como_resultado}
                {translations.PoliticsComercial?.A_partir_del}
                {translations.PoliticsComercial?.Si_el_retraso}
                </div>
                </div>
                <div className="subtitulo-politics">
                    <span>{translations.PoliticsComercial?.Limite_credito}</span><br/><br/>
                    {translations.PoliticsComercial?.Sin_excepcion}
                <br/><br/>

                </div>

                <div className="subtitulo-politics">
                <span>{translations.PoliticsComercial?.Canales_distribucion}</span><br/><br/>
                {translations.PoliticsComercial?.Los_canales}
                <ul>
                    <li>
                    <b>{translations.PoliticsComercial?.Tiendas_Boutique}</b> {translations.PoliticsComercial?.Son_tiendas}
                    {translations.PoliticsComercial?.Por_lo_general}
                    </li>
                    <li>
                    <b>{translations.PoliticsComercial?.IBDs} </b> {translations.PoliticsComercial?.Tiendas_ciclismo}
                    </li>
                    <li>
                    <b>{translations.PoliticsComercial?.Tiendas_Departamentales}</b> {translations.PoliticsComercial?.Son_especializas}
                    </li>
                    <li>
                    <b>{translations.PoliticsComercial?.Ecommerce}</b>{translations.PoliticsComercial?.Son_clientes_cuya}
                    </li>
                    <li>
                    <b>{translations.PoliticsComercial?.Mayoristas}</b> {translations.PoliticsComercial?.herramientas_logisticas}
                    </li>
                    <li>
                    <b>{translations.PoliticsComercial?.OEM}</b> {translations.PoliticsComercial?.operacion_armado}
                    </li>
                    <li>
                    <b>{translations.PoliticsComercial?.Establecimientos}</b> {translations.PoliticsComercial?.Establecimientos}
                    </li>
                </ul>
               
                </div>
               
                <div className="subtitulo-politics">
                    <span>{translations.PoliticsComercial?.Descuentos_4}</span><br/><br/> {translations.PoliticsComercial?.Mexico_empezara}<br/><br/>

                    <ul>
                        <li>
                        <b>0%:</b> {translations.PoliticsComercial?.facturacion_0}
                        </li>
                        <li>
                        <b>2%:</b> {translations.PoliticsComercial?.facturacion_2}
                        </li>
                        <li>
                        <b>4%:</b> {translations.PoliticsComercial?.facturacion_4}
                        </li>
                        <li>
                        <b>6%:</b> {translations.PoliticsComercial?.facturacion_6}
                        </li>
                        <li>
                        <b>8%:</b> {translations.PoliticsComercial?.facturacion_8p}
                        </li>
                        <li>
                        <b>12%:</b> {translations.PoliticsComercial?.facturacion_12}
                        </li>
                    </ul>
                    <b>{translations.PoliticsComercial?.OEM}</b> {translations.PoliticsComercial?.comprobar_operacion}<br/><br/>
                    <b>{translations.PoliticsComercial?.Bikefitting}</b> {translations.PoliticsComercial?.Aplica_este_descuento} <br/><br/>
                    <b>{translations.PoliticsComercial?.Todos_descuentos}</b><br/><br/>
                    {translations.PoliticsComercial?.inicios_la_operacion}<br/><br/>
                </div>

                <div className="subtitulo-politics">
                    <span>{translations.PoliticsComercial?.Minimo_5}</span><br/><br/>
                    {translations.PoliticsComercial?.operacion_establecera}<br/><br/>
                </div>

                <div className="subtitulo-politics">
                    <span>{translations.PoliticsComercial?.siguientes_documentos}</span><br/><br/>
                    {translations.PoliticsComercial?.siguientes_documentos}<br/><br/>
                </div>

                <div className="subtitulo-politics">
                    <span>{translations.PoliticsComercial?.Metodos_7}</span><br/><br/>
                    {translations.PoliticsComercial?.Todas_las_facturas} <b>{translations.PoliticsComercial?.aceptan_pagos}</b>. 
                    {translations.PoliticsComercial?.Del_mismo}<b>{translations.PoliticsComercial?.indispensable}</b> {translations.PoliticsComercial?.numero_de_referencia} <b>{translations.PoliticsComercial?.numero_factura}</b> {translations.PoliticsComercial?.transaccion}
                    {translations.PoliticsComercial?.incluir_referencia} 
                </div>
                <div className="subtitulo-politics">
                    <span>{translations.PoliticsComercial?.Facturacion_8}</span><br/><br/>
                    {translations.PoliticsComercial?.pedidos_ingresados} <b>{translations.PoliticsComercial?.ultimos_3}</b> {translations.PoliticsComercial?.debido_carga}<br/><br/>

                    {translations.PoliticsComercial?.conocer_sus_condiciones}
                </div>
            </section>
            
        </>
    );
}

export default Politics;